import { formatDate } from "@angular/common";
import { Component, Inject, LOCALE_ID, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ChartComponent } from "ng-apexcharts";
import { PharmacyService } from "../pharmacy.service";
import { PharmacyTotalIncomeDialogComponent } from "./pharmacy-total-income-dialog/pharmacy-total-income-dialog.component";
import { LanguagePharmacy } from "../interface-pharmacy/language-item-pharmacy";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";

@Component({
  selector: "app-pharmacy-total-income",
  templateUrl: "./pharmacy-total-income.component.html",
  styleUrls: ["./pharmacy-total-income.component.scss"],
})
export class PharmacyTotalIncomeComponent implements OnInit {
  dataTrackingRequest: any = [];
  idPharmacy: string;
  sumqty: number = 0;
  sum: any;
  numMoneyDay: number = 0;
  numMoneyMonth: number = 0;
  numMoneyAll: number = 0;
  dataTotalIncomeDay: any;
  countOrderDay: number = 0;
  dataTotalIncomeMonth: any;
  countOrderMonth: number = 0;
  dataTotalIncomeYear: any;
  dataTotalIncomeAll: any;
  countOrderAll: number = 0;
  dateNow = new Date();

  dataLoading: boolean = true;
  dataLoadingIncomeDay: boolean = true;
  dataLoadingIncomeToYear: boolean = true;

  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    "idOrder",
    "time",
    "orderList",
    "price",
    "payment",
    "transferStutus",
  ];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  yearNow = formatDate(new Date(), "yyyy", this.locale);

  languagePharmacy: any;
  datatranslate: any;

  AnnualIncome: any = "";
  OrderYear: any = "";
  dateThai: String;
  dateEng: String;
  dateThai1: String;
  dateEng1: String;

  @ViewChild("chart") chart: ChartComponent;

  public barChartTotelIncome: any = [];
  public barChartOrderAll: any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    public pharmacyService: PharmacyService,
    @Inject(LOCALE_ID) private locale: string,
    public dialog: MatDialog,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);
    this.idPharmacy = dataLocalJSON.id;
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
      if (this.datatranslate == "th") {
        this.AnnualIncome = "รายได้ต่อปี ";
        this.OrderYear = "ยอดสั่งซื้อต่อปี ";
      } else {
        this.AnnualIncome = "Annual Income ";
        this.OrderYear = "Order Year ";
      }
      var m2 = this.dateNow.getMonth();
      var month1 = this.dateNow.getTime();
      var month2 = new Date(`${m2}/24/2023`).getTime();
      const date1 = new Date(month1);
      this.dateThai = date1.toLocaleDateString("th-TH", {
        month: "long",
      });
      this.dateEng = date1.toLocaleDateString("en-EN", {
        month: "long",
      });
      const date2 = new Date(month2);
      this.dateThai1 = date2.toLocaleDateString("th-TH", {
        month: "long",
      });
      this.dateEng1 = date2.toLocaleDateString("en-EN", {
        month: "long",
      });

      this.loadDateToyear();
      this.loadOrderToyear();
    });

    this.loadDataTotalIncomeDay("day", this.idPharmacy);
    this.loadDataTotalIncomeMonth("month", this.idPharmacy);
    this.loadDataTotalIncomeYear("year", this.idPharmacy);
    this.loadDataTotalIncomeAll("all", this.idPharmacy);
  }

  private chart1(dataTotelIncomeM: any) {
    this.barChartTotelIncome = {
      series: [
        {
          name: "Totel Income",
          data: [
            dataTotelIncomeM[0].totalPrice,
            dataTotelIncomeM[1].totalPrice,
            dataTotelIncomeM[2].totalPrice,
            dataTotelIncomeM[3].totalPrice,
            dataTotelIncomeM[4].totalPrice,
            dataTotelIncomeM[5].totalPrice,
            dataTotelIncomeM[6].totalPrice,
            dataTotelIncomeM[7].totalPrice,
            dataTotelIncomeM[8].totalPrice,
            dataTotelIncomeM[9].totalPrice,
            dataTotelIncomeM[10].totalPrice,
            dataTotelIncomeM[11].totalPrice,
          ],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
      },
      colors: ["#ff7f50"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#9aa0ac"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "top",
        labels: {
          offsetY: -18,
          style: {
            colors: "#9aa0ac",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#ff7f50",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
          offsetY: -35,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100],
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return "฿ " + val;
          },
        },
      },
      title: {
        text: this.AnnualIncome + new Date().getFullYear(),
        floating: 0,
        offsetY: 330,
        align: "center",
        style: {
          color: "#9aa0ac",
        },
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };
  }
  private chart2(dataOrderM: any) {
    this.barChartOrderAll = {
      series: [
        {
          name: "Order",
          data: [
            dataOrderM[0].totalPrice,
            dataOrderM[1].totalPrice,
            dataOrderM[2].totalPrice,
            dataOrderM[3].totalPrice,
            dataOrderM[4].totalPrice,
            dataOrderM[5].totalPrice,
            dataOrderM[6].totalPrice,
            dataOrderM[7].totalPrice,
            dataOrderM[8].totalPrice,
            dataOrderM[9].totalPrice,
            dataOrderM[10].totalPrice,
            dataOrderM[11].totalPrice,
          ],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
      },
      colors: ["#FD6209"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#9aa0ac"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "top",
        labels: {
          offsetY: -18,
          style: {
            colors: "#9aa0ac",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#FD6209",
              colorTo: "#ee9ca7",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
          offsetY: -35,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100],
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + " List";
          },
        },
      },
      title: {
        text: this.OrderYear + new Date().getFullYear(),
        floating: 0,
        offsetY: 330,
        align: "center",
        style: {
          color: "#9aa0ac",
        },
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };
  }

  onChengDate() {
    var dataStart: string = "";
    var dataEnd: string = "";
    var resultOrderDrugSuccess: any = [];

    dataStart = this.range.value.start;
    dataEnd = this.range.value.end;

    if (this.range.value.start != null && this.range.value.end != null) {
      this.dataSource = new MatTableDataSource([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      for (let i = 0; i < this.dataTotalIncomeAll.length; i++) {
        if (
          (this.dataTotalIncomeAll[i].recpT_TransferStutus == true &&
            this.dataTotalIncomeAll[i].recpT_TypePayment == "transfer") ||
          (this.dataTotalIncomeAll[i].recpT_StatusPay == true &&
            this.dataTotalIncomeAll[i].recpT_TypePayment == "digipay")
        ) {
          resultOrderDrugSuccess.push(this.dataTotalIncomeAll[i]);
        }
      }

      var newSelectDate = resultOrderDrugSuccess.filter((item: any) => {
        return (
          new Date(item.ordeR_Date).getDate() >=
          new Date(dataStart).getDate() &&
          new Date(item.ordeR_Date).getDate() <= new Date(dataEnd).getDate() &&
          new Date(item.ordeR_Date).getMonth() >=
          new Date(dataStart).getMonth() &&
          new Date(item.ordeR_Date).getMonth() <=
          new Date(dataEnd).getMonth() &&
          new Date(item.ordeR_Date).getFullYear() >=
          new Date(dataStart).getFullYear() &&
          new Date(item.ordeR_Date).getFullYear() <=
          new Date(dataEnd).getFullYear()
        );
      });

      this.dataSource = new MatTableDataSource(newSelectDate);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      setInterval(() => {
        this.dataLoading = false;
      }, 1000);
    }
  }

  reloadDate() {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource = new MatTableDataSource(this.dataTotalIncomeAll);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadDataTotalIncomeDay(dayID: string, idUser: string) {
    var dataGetTotalIncomeDay: any;
    this.numMoneyDay = 0;
    this.pharmacyService
      .GetPhamacyTotalIncome_(dayID, idUser)
      .then((resGetTotalIncomeDay) => {
        dataGetTotalIncomeDay = resGetTotalIncomeDay;
        this.dataTotalIncomeDay = dataGetTotalIncomeDay;

        for (let i = 0; i < this.dataTotalIncomeDay.length; i++) {
          if (
            (this.dataTotalIncomeDay[i].recpT_TransferStutus == true &&
              this.dataTotalIncomeDay[i].recpT_TypePayment == "transfer") ||
            (this.dataTotalIncomeDay[i].recpT_StatusPay == true &&
              this.dataTotalIncomeDay[i].recpT_TypePayment == "digipay")
          ) {
            this.numMoneyDay =
              this.numMoneyDay + this.dataTotalIncomeDay[i].recpT_TotalPrice;
            this.countOrderDay = this.countOrderDay + 1;
          }
        }
      });
    setInterval(() => {
      this.dataLoadingIncomeDay = false;
    }, 1000);
  }

  loadDataTotalIncomeMonth(monthID: string, idUser: string) {
    var dataGetTotalIncomeMonth: any;
    this.numMoneyMonth = 0;
    this.pharmacyService
      .GetPhamacyTotalIncome_(monthID, idUser)
      .then((resGetTotalIncomeMonth) => {
        dataGetTotalIncomeMonth = resGetTotalIncomeMonth;
        this.dataTotalIncomeMonth = dataGetTotalIncomeMonth;

        for (let i = 0; i < this.dataTotalIncomeMonth.length; i++) {
          if (
            (this.dataTotalIncomeMonth[i].recpT_TransferStutus == true &&
              this.dataTotalIncomeMonth[i].recpT_TypePayment == "transfer") ||
            (this.dataTotalIncomeMonth[i].recpT_StatusPay == true &&
              this.dataTotalIncomeMonth[i].recpT_TypePayment == "digipay")
          ) {
            this.numMoneyMonth =
              this.numMoneyMonth +
              this.dataTotalIncomeMonth[i].recpT_TotalPrice;
            this.countOrderMonth = this.countOrderMonth + 1;
          }
        }
      });
    setInterval(() => {
      this.dataLoading = false;
    }, 1000);
  }

  loadDataTotalIncomeYear(yearID: string, idUser: string) {
    var dataGetTotalIncomeYear: any;
    this.pharmacyService
      .GetPhamacyTotalIncome_(yearID, idUser)
      .then((resGetTotalIncomeYear) => {
        dataGetTotalIncomeYear = resGetTotalIncomeYear;
        this.dataTotalIncomeYear = dataGetTotalIncomeYear;
      });
    setInterval(() => {
      this.dataLoadingIncomeToYear = false;
    }, 1000);
  }
  loadDataTotalIncomeAll(allID: string, idUser: string) {
    var dataGetTotalIncomeAll: any;
    var resultOrderDrugSuccess: any = [];
    this.numMoneyAll = 0;
    this.pharmacyService
      .GetPhamacyTotalIncome_(allID, idUser)
      .then((resGetTotalIncomeAll) => {
        dataGetTotalIncomeAll = resGetTotalIncomeAll;
        this.dataTotalIncomeAll = dataGetTotalIncomeAll;

        for (let i = 0; i < this.dataTotalIncomeAll.length; i++) {
          if (
            (this.dataTotalIncomeAll[i].recpT_TransferStutus == true &&
              this.dataTotalIncomeAll[i].recpT_TypePayment == "transfer") ||
            (this.dataTotalIncomeAll[i].recpT_StatusPay == true &&
              this.dataTotalIncomeAll[i].recpT_TypePayment == "digipay")
          ) {
            resultOrderDrugSuccess.push(this.dataTotalIncomeAll[i]);
            var yearData = formatDate(
              this.dataTotalIncomeAll[i].ordeR_Date,
              "yyyy",
              this.locale
            );
            var yearNow = formatDate(new Date(), "yyyy", this.locale);
            if (yearData == yearNow) {
              this.numMoneyAll =
                this.numMoneyAll + this.dataTotalIncomeAll[i].recpT_TotalPrice;
              this.countOrderAll = this.countOrderAll + 1;
            }
          }
        }

        this.dataSource = new MatTableDataSource(resultOrderDrugSuccess);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        setInterval(() => {
          this.dataLoading = false;
        }, 1000);
        this.loadOrderToyear();
        this.loadDateToyear();
      });
  }

  loadDateToyear() {
    var date = [];
    var resultDate = [];
    var year = formatDate(new Date(), "yyyy", this.locale);
    var mount = [
      "-01-01",
      "-02-01",
      "-03-01",
      "-04-01",
      "-05-01",
      "-06-01",
      "-07-01",
      "-08-01",
      "-09-01",
      "-10-01",
      "-11-01",
      "-12-01",
    ];
    for (let i = 0; i < mount.length; i++) {
      date.push(year + mount[i]);
    }

    for (let i = 0; i < date.length; i++) {
      var m = [];
      m = this.dataTotalIncomeAll.filter((item: any) => {
        return (
          new Date(item.ordeR_Date).getMonth() >=
          new Date(date[i]).getMonth() &&
          new Date(item.ordeR_Date).getMonth() <= new Date(date[i]).getMonth()
        );
      });
      if (m.length > 0) {
        var sum = 0;
        for (let j = 0; j < m.length; j++) {
          var yearData = formatDate(m[j].ordeR_Date, "yyyy", this.locale);
          var yearNow = formatDate(new Date(), "yyyy", this.locale);
          if (yearData == yearNow) {
            if (
              (m[j].recpT_TransferStutus == true &&
                m[j].recpT_TypePayment == "transfer") ||
              (m[j].recpT_StatusPay == true &&
                m[j].recpT_TypePayment == "digipay")
            ) {
              sum = sum + m[j].recpT_TotalPrice;
            }
          }
        }
        resultDate.push({ mount: i + 1, totalPrice: sum });
      } else {
        resultDate.push({ mount: i + 1, totalPrice: 0 });
      }
    }
    this.chart1(resultDate);
  }
  loadOrderToyear() {
    var date = [];
    var resultOrder = [];
    var year = formatDate(new Date(), "yyyy", this.locale);
    var mount = [
      "-01-01",
      "-02-01",
      "-03-01",
      "-04-01",
      "-05-01",
      "-06-01",
      "-07-01",
      "-08-01",
      "-09-01",
      "-10-01",
      "-11-01",
      "-12-01",
    ];
    for (let i = 0; i < mount.length; i++) {
      date.push(year + mount[i]);
    }

    for (let i = 0; i < date.length; i++) {
      var m = [];
      m = this.dataTotalIncomeAll.filter((item: any) => {
        return (
          new Date(item.ordeR_Date).getMonth() >=
          new Date(date[i]).getMonth() &&
          new Date(item.ordeR_Date).getMonth() <= new Date(date[i]).getMonth()
        );
      });

      if (m.length > 0) {
        var sum = 0;
        var sumnum = 0;
        for (let j = 0; j < m.length; j++) {
          var yearData = formatDate(m[j].ordeR_Date, "yyyy", this.locale);
          var yearNow = formatDate(new Date(), "yyyy", this.locale);
          if (yearData == yearNow) {
            if (
              (m[j].recpT_TransferStutus == true &&
                m[j].recpT_TypePayment == "transfer") ||
              (m[j].recpT_StatusPay == true &&
                m[j].recpT_TypePayment == "digipay")
            ) {
              sum = sum + m[j].orderList;
              sumnum = sumnum + 1;
            }
          }
        }
        resultOrder.push({ mount: i + 1, totalPrice: sumnum });
      } else {
        resultOrder.push({ mount: i + 1, totalPrice: 0 });
      }
    }
    this.chart2(resultOrder);
  }

  detailsTotalIncome(row) {
    this.dialog.open(PharmacyTotalIncomeDialogComponent, {
      data: row,
    });
  }
}
