import { Component, Inject, LOCALE_ID, OnInit } from "@angular/core";
import * as moment from "moment";
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexTooltip,
  ApexLegend,
  ApexFill,
  ApexNonAxisChartSeries,
  ApexPlotOptions,
} from "ng-apexcharts";
import { PharmacyService } from "../pharmacy.service";
import { formatDate } from "@angular/common";
import { GlobalConstants } from "src/app/common/global-constants";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "../interface-pharmacy/language-item-pharmacy";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public lineChartOptions: Partial<ChartOptions>;

  urlPath = GlobalConstants.REST_API_TELE;
  dateNow = new Date();
  public barChartTotelIncome: any;
  public barChartOrderAll: any;
  idPharmacy: string;
  // dataLoadingBarChartTotalIncome: boolean = true;
  // dataLoadingBarChartTotalOrder: boolean = true;
  dataTotalIncomeAll: any;
  numMoneyAll: number = 0;
  countOrderAll: number = 0;

  dataTotalIncomeMonth: any;
  numMoneyMonth: number = 0;
  numMoneyWeek: number = 0;
  countOrderMonth: number = 0;
  countOrderWeek: number = 0;

  dataTotalIncomeDay: any;
  numMoneyDay: number = 0;
  countOrderDay: number = 0;

  rating: any;
  incomelastMonthCompareCurrentMonth: any;
  succesAll1: any;
  succesAll2: any;

  dataTopTenMore: any = [];
  dataTopTenLess: any = [];

  ttLoading: boolean = true;
  languagePharmacy: any;
  datatranslate: any;
  dateThai: String;
  dateEng: String;
  dateThai1: String;
  dateEng1: String;
  dateNowThai: String;
  dateNowEng: String;

  caseLang: any = "";
  AnnualIncome: any = "";
  OrderYear: any = "";
  constructor(
    public pharmacyService: PharmacyService,
    @Inject(LOCALE_ID) private locale: string,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);
    this.idPharmacy = dataLocalJSON.id;
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
      if (this.datatranslate == "th") {
        this.caseLang = "เคสของเดือน ";
        this.AnnualIncome = "รายได้ต่อปี ";
        this.OrderYear = "ยอดสั่งซื้อต่อปี ";
      } else {
        this.caseLang = "Case in ";
        this.AnnualIncome = "Annual Income ";
        this.OrderYear = "Order Year ";
      }
      var m2 = this.dateNow.getMonth();
      var month1 = this.dateNow.getTime();
      var month2 = new Date(`${m2}/24/2023`).getTime();
      const date1 = new Date(month1);
      this.dateThai = date1.toLocaleDateString("th-TH", {
        month: "long",
      });
      this.dateEng = date1.toLocaleDateString("en-EN", {
        month: "long",
      });
      const date2 = new Date(month2);
      this.dateThai1 = date2.toLocaleDateString("th-TH", {
        month: "long",
      });
      this.dateEng1 = date2.toLocaleDateString("en-EN", {
        month: "long",
      });

      // var month2 = month.AddMonths(-1);
      this.ChartSemi();
      this.loadDateToyear();
      this.loadOrderToyear();
    });
    this.loadDataTotalIncomeAll("all", this.idPharmacy);
    this.loadDataTotalIncomeMonth("month", this.idPharmacy);
    this.loadDataTotalIncomeDay("day", this.idPharmacy);

    this.getRating(this.idPharmacy);
    this.getIncomelastMonthCompareCurrentMonth(this.idPharmacy);
    this.getPhamarcyTopTenMedicineAll(this.idPharmacy);

    this.totalReportLoading();
  }

  public chartSemi1Data: string[] = [];
  public chartSemi1Month: string[] = [];
  public chartSemi2Data: string[] = [];
  public chartSemi2Month: string[] = [];

  chartSemi: any = [];
  chartSemi2: any = [];
  chartSemiGreen: string = "#ff7f50";
  chartSemiRed: string = "#E76412";
  chartSemiSelectColor1: string;
  chartSemiSelectColor2: string;

  totalReportLoading() {
    setTimeout(() => {
      this.ttLoading = false;
    }, 1500);
  }

  ChartSemi() {
    this.chartSemi = {
      series: {
        data: [100],
      },
      title: {
        text: this.datatranslate == "th" ? this.caseLang + this.dateThai : this.caseLang + this.dateEng,
        align: "center",
        margin: 10,
        offsetY: 30,
        floating: false,
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          color: "#000",
        },
      },
      chart: {
        type: "radialBar",
        offsetY: -20,
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "22px",
              fontWeight: "bold",
              offsetY: 0,
              formatter: function (val, opts) {
                return val;
              },
            },
          },
        },
      },
      fill: {
        colors: [this.chartSemiSelectColor1],
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
    };

    this.chartSemi2 = {
      series: {
        data: [100],
      },
      title: {
        text: this.datatranslate == "th" ? this.caseLang + this.dateThai1 : this.caseLang + this.dateEng1,
        align: "center",
        margin: 10,
        offsetY: 30,
        floating: false,
        style: {
          fontSize: "16px",
          fontWeight: "bold",
          color: "#000",
        },
      },
      chart: {
        type: "radialBar",
        offsetY: -20,
        animations: {
          enabled: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: "97%",
            margin: 5,
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              opacity: 0.31,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "22px",
              fontWeight: "bold",
              offsetY: 0,
              formatter: function (val, opts) {
                return val;
              },
            },
          },
        },
      },
      fill: {
        colors: [this.chartSemiSelectColor2],
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
    };
  }

  loadDataTotalIncomeAll(allID: string, idUser: string) {
    var dataGetTotalIncomeAll: any;
    var resultOrderDrugSuccess: any = [];
    this.numMoneyAll = 0;
    this.pharmacyService
      .GetPhamacyTotalIncome_(allID, idUser)
      .then((resGetTotalIncomeAll) => {
        dataGetTotalIncomeAll = resGetTotalIncomeAll;
        this.dataTotalIncomeAll = dataGetTotalIncomeAll;

        for (let i = 0; i < this.dataTotalIncomeAll.length; i++) {
          var yearData = formatDate(
            this.dataTotalIncomeAll[i].ordeR_Date,
            "yyyy",
            this.locale
          );
          var yearNow = formatDate(new Date(), "yyyy", this.locale);
          if (yearData == yearNow) {
            if (
              (this.dataTotalIncomeAll[i].recpT_TransferStutus == true &&
                this.dataTotalIncomeAll[i].recpT_TypePayment == "transfer") ||
              (this.dataTotalIncomeAll[i].recpT_StatusPay == true &&
                this.dataTotalIncomeAll[i].recpT_TypePayment == "digipay")
            ) {
              resultOrderDrugSuccess.push(this.dataTotalIncomeAll[i]);

              this.numMoneyAll =
                this.numMoneyAll + this.dataTotalIncomeAll[i].recpT_TotalPrice;
              this.countOrderAll = this.countOrderAll + 1;
            }
          }

          if (i == this.dataTotalIncomeAll.length - 1) {
            this.loadDateToyear();
            this.loadOrderToyear();
          }
        }
      });
  }

  loadDataTotalIncomeMonth(monthID: string, idUser: string) {
    var startOfWeek = moment().startOf("week").toDate();
    var endOfWeek = moment().endOf("week").toDate();

    var dataGetTotalIncomeMonth: any;
    this.numMoneyMonth = 0;
    this.numMoneyWeek = 0;
    this.pharmacyService
      .GetPhamacyTotalIncome_(monthID, idUser)
      .then((resGetTotalIncomeMonth) => {
        dataGetTotalIncomeMonth = resGetTotalIncomeMonth;
        this.dataTotalIncomeMonth = dataGetTotalIncomeMonth;

        for (let i = 0; i < this.dataTotalIncomeMonth.length; i++) {
          if (
            (this.dataTotalIncomeMonth[i].recpT_TransferStutus == true &&
              this.dataTotalIncomeMonth[i].recpT_TypePayment == "transfer") ||
            (this.dataTotalIncomeMonth[i].recpT_StatusPay == true &&
              this.dataTotalIncomeMonth[i].recpT_TypePayment == "digipay")
          ) {
            this.numMoneyMonth =
              this.numMoneyMonth +
              this.dataTotalIncomeMonth[i].recpT_TotalPrice;
            this.countOrderMonth = this.countOrderMonth + 1;

            //week
            if (
              moment(this.dataTotalIncomeMonth[i].ordeR_Date).toDate() >=
              startOfWeek &&
              moment(this.dataTotalIncomeMonth[i].ordeR_Date).toDate() <=
              endOfWeek
            ) {
              this.numMoneyWeek =
                this.numMoneyWeek +
                this.dataTotalIncomeMonth[i].recpT_TotalPrice;
              this.countOrderWeek = this.countOrderWeek + 1;
            }
          }
        }
      });
    // setInterval(() => {
    //   this.dataLoading = false;
    // }, 1000);
  }

  loadDataTotalIncomeDay(dayID: string, idUser: string) {
    var dataGetTotalIncomeDay: any;
    this.numMoneyDay = 0;
    this.pharmacyService
      .GetPhamacyTotalIncome_(dayID, idUser)
      .then((resGetTotalIncomeDay) => {
        dataGetTotalIncomeDay = resGetTotalIncomeDay;
        this.dataTotalIncomeDay = dataGetTotalIncomeDay;

        for (let i = 0; i < this.dataTotalIncomeDay.length; i++) {
          var yearData = formatDate(
            this.dataTotalIncomeDay[i].ordeR_Date,
            "yyyy",
            this.locale
          );
          var yearNow = formatDate(new Date(), "yyyy", this.locale);
          if (yearData == yearNow) {
            if (
              (this.dataTotalIncomeDay[i].recpT_TransferStutus == true &&
                this.dataTotalIncomeDay[i].recpT_TypePayment == "transfer") ||
              (this.dataTotalIncomeDay[i].recpT_StatusPay == true &&
                this.dataTotalIncomeDay[i].recpT_TypePayment == "digipay")
            ) {
              this.numMoneyDay =
                this.numMoneyDay + this.dataTotalIncomeDay[i].recpT_TotalPrice;
              this.countOrderDay = this.countOrderDay + 1;
            }
          }
        }
      });
    // setInterval(() => {
    //   this.dataLoadingIncomeDay = false;
    // }, 1000);
  }

  loadDateToyear() {
    var date = [];
    var resultDate = [];
    var year = formatDate(new Date(), "yyyy", this.locale);
    var mount = [
      "-01-01",
      "-02-01",
      "-03-01",
      "-04-01",
      "-05-01",
      "-06-01",
      "-07-01",
      "-08-01",
      "-09-01",
      "-10-01",
      "-11-01",
      "-12-01",
    ];
    for (let i = 0; i < mount.length; i++) {
      date.push(year + mount[i]);
    }

    for (let i = 0; i < date.length; i++) {
      var m = [];
      m = this.dataTotalIncomeAll.filter((item: any) => {
        return (
          new Date(item.ordeR_Date).getMonth() >=
          new Date(date[i]).getMonth() &&
          new Date(item.ordeR_Date).getMonth() <= new Date(date[i]).getMonth()
        );
      });

      if (m.length > 0) {
        var sum = 0;
        for (let j = 0; j < m.length; j++) {
          var yearData = formatDate(m[j].ordeR_Date, "yyyy", this.locale);
          var yearNow = formatDate(new Date(), "yyyy", this.locale);
          if (yearData == yearNow) {
            if (
              (m[j].recpT_TransferStutus == true &&
                m[j].recpT_TypePayment == "transfer") ||
              (m[j].recpT_StatusPay == true &&
                m[j].recpT_TypePayment == "digipay")
            ) {
              sum = sum + m[j].recpT_TotalPrice;
            }
          }
        }
        resultDate.push({ mount: i + 1, totalPrice: sum.toFixed(2) });
      } else {
        resultDate.push({ mount: i + 1, totalPrice: 0 });
      }
      if (i == date.length - 1) {
        this.setDataBarChartTotelIncomeMonth(resultDate);
        // setTimeout(() => {
        //   this.dataLoadingBarChartTotalIncome = false;
        // }, 1000);
      }
    }
  }

  loadOrderToyear() {
    var date = [];
    var resultOrder = [];
    var year = formatDate(new Date(), "yyyy", this.locale);
    var mount = [
      "-01-01",
      "-02-01",
      "-03-01",
      "-04-01",
      "-05-01",
      "-06-01",
      "-07-01",
      "-08-01",
      "-09-01",
      "-10-01",
      "-11-01",
      "-12-01",
    ];
    for (let i = 0; i < mount.length; i++) {
      date.push(year + mount[i]);
    }

    for (let i = 0; i < date.length; i++) {
      var m = [];
      m = this.dataTotalIncomeAll.filter((item: any) => {
        return (
          new Date(item.ordeR_Date).getMonth() >=
          new Date(date[i]).getMonth() &&
          new Date(item.ordeR_Date).getMonth() <= new Date(date[i]).getMonth()
        );
      });

      if (m.length > 0) {
        var sum = 0;
        var sumnum = 0;
        for (let j = 0; j < m.length; j++) {
          var yearData = formatDate(m[j].ordeR_Date, "yyyy", this.locale);
          var yearNow = formatDate(new Date(), "yyyy", this.locale);
          if (yearData == yearNow) {
            if (
              (m[j].recpT_TransferStutus == true &&
                m[j].recpT_TypePayment == "transfer") ||
              (m[j].recpT_StatusPay == true &&
                m[j].recpT_TypePayment == "digipay")
            ) {
              sum = sum + m[j].orderList;
              sumnum = sumnum + 1;
            }
          }
        }
        resultOrder.push({ mount: i + 1, totalPrice: sumnum });
      } else {
        resultOrder.push({ mount: i + 1, totalPrice: 0 });
      }
    }
    this.setDataBarChartTotelOrderMonth(resultOrder);
    // setTimeout(() => {
    //   this.dataLoadingBarChartTotalOrder = false;
    // }, 1000);
  }

  private setDataBarChartTotelIncomeMonth(dataTotelIncomeM: any) {
    this.barChartTotelIncome = {
      series: [
        {
          name: "Totel Income",
          data: [
            dataTotelIncomeM[0].totalPrice,
            dataTotelIncomeM[1].totalPrice,
            dataTotelIncomeM[2].totalPrice,
            dataTotelIncomeM[3].totalPrice,
            dataTotelIncomeM[4].totalPrice,
            dataTotelIncomeM[5].totalPrice,
            dataTotelIncomeM[6].totalPrice,
            dataTotelIncomeM[7].totalPrice,
            dataTotelIncomeM[8].totalPrice,
            dataTotelIncomeM[9].totalPrice,
            dataTotelIncomeM[10].totalPrice,
            dataTotelIncomeM[11].totalPrice,
          ],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
      },
      colors: ["#ff7f50"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#9aa0ac"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "top",
        labels: {
          offsetY: -18,
          style: {
            colors: "#9aa0ac",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#ff7f50",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
          offsetY: -35,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100],
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return "฿ " + val;
          },
        },
      },
      title: {
        text: this.AnnualIncome + new Date().getFullYear(),
        floating: 0,
        offsetY: 330,
        align: "center",
        style: {
          color: "#9aa0ac",
        },
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };
  }

  private setDataBarChartTotelOrderMonth(dataOrderM: any) {
    this.barChartOrderAll = {
      series: [
        {
          name: "Order",
          data: [
            dataOrderM[0].totalPrice,
            dataOrderM[1].totalPrice,
            dataOrderM[2].totalPrice,
            dataOrderM[3].totalPrice,
            dataOrderM[4].totalPrice,
            dataOrderM[5].totalPrice,
            dataOrderM[6].totalPrice,
            dataOrderM[7].totalPrice,
            dataOrderM[8].totalPrice,
            dataOrderM[9].totalPrice,
            dataOrderM[10].totalPrice,
            dataOrderM[11].totalPrice,
          ],
        },
      ],
      chart: {
        height: 350,
        type: "bar",
      },
      colors: ["#FD6209"],
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#9aa0ac"],
        },
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "top",
        labels: {
          offsetY: -18,
          style: {
            colors: "#9aa0ac",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#FD6209",
              colorTo: "#ee9ca7",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: true,
          offsetY: -35,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100],
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + " List";
          },
        },
      },
      title: {
        text: this.OrderYear + new Date().getFullYear(),
        floating: 0,
        offsetY: 330,
        align: "center",
        style: {
          color: "#9aa0ac",
        },
      },
      tooltip: {
        theme: "dark",
        marker: {
          show: true,
        },
        x: {
          show: true,
        },
      },
    };
  }

  getRating(idConver: any) {
    var getdataRating: any = [];
    this.pharmacyService.GetRaitingPhamacy_(idConver).then((resGetRating) => {
      getdataRating = resGetRating;
      if (getdataRating.month1 == null) {
        getdataRating.month1 = this.dateNow;
      }
      if (getdataRating.raitingMonthCount1 == null) {
        getdataRating.raitingMonthCount1 = 0;
      }
      if (getdataRating.raitingMonthSum1 == null) {
        getdataRating.raitingMonthSum1 = 0;
      }
      var costomRating = {
        month1: getdataRating.month1,
        month2: getdataRating.month2,
        raitingMonthCount1: getdataRating.raitingMonthCount1,
        raitingMonthCount2: getdataRating.raitingMonthCount2,
        raitingMonthScore1: getdataRating.raitingMonthScore1,
        raitingMonthScore2: getdataRating.raitingMonthScore2,
        raitingMonthSum1: getdataRating.raitingMonthSum1,
        raitingMonthSum2: getdataRating.raitingMonthSum2,
      };

      this.rating = costomRating;
      const dateNow = new Date(getdataRating.month1);
      this.dateNowThai = this.dateNow.toLocaleDateString("th-TH", {
        weekday: "long",
      });
      this.dateNowEng = this.dateNow.toLocaleDateString("en-EN", {
        weekday: "long",
      });

      const date = new Date(this.rating.month1);
      this.dateThai = date.toLocaleDateString("th-TH", {
        month: "long",
      });
      this.dateEng = date.toLocaleDateString("en-EN", {
        month: "long",
      });

      const date1 = new Date(this.rating.month2);
      this.dateThai1 = date1.toLocaleDateString("th-TH", {
        month: "long",
      });
      this.dateEng1 = date1.toLocaleDateString("en-EN", {
        month: "long",
      });
    });
  }

  getIncomelastMonthCompareCurrentMonth(idConver: any) {
    this.pharmacyService
      .GetPhamarcyIncomelastMonthCompareCurrentMonth_(idConver)
      .then((resGetIncomelastMonthCompareCurrentMonth) => {
        this.incomelastMonthCompareCurrentMonth =
          resGetIncomelastMonthCompareCurrentMonth;
        var incomelastMonthmonth1 = moment(
          this.incomelastMonthCompareCurrentMonth.month1
        ).format("MMMM");
        var incomelastMonthmonth2 = moment(
          this.incomelastMonthCompareCurrentMonth.month2
        ).format("MMMM");
        var incomelastMonthorderAll1 =
          this.incomelastMonthCompareCurrentMonth.orderAll1;
        var incomelastMonthorderAll2 =
          this.incomelastMonthCompareCurrentMonth.orderAll2;
        if (
          (incomelastMonthorderAll1 == "0" &&
            incomelastMonthorderAll2 == "0") ||
          (incomelastMonthorderAll1 == null && incomelastMonthorderAll2 == null)
        ) {
          this.chartSemi1Data.push("0");
          this.chartSemi2Data.push("0");
        } else {
          this.chartSemi1Data.push(incomelastMonthorderAll1);
          this.chartSemi2Data.push(incomelastMonthorderAll2);
        }
        this.chartSemi1Month.push("Order in " + incomelastMonthmonth1 + ":");
        this.chartSemi2Month.push("Order in " + incomelastMonthmonth2 + ":");

        if (
          this.incomelastMonthCompareCurrentMonth.totalPriceAll1 >
          this.incomelastMonthCompareCurrentMonth.totalPriceAll2
        ) {
          this.succesAll1 = "succesAll";
          this.succesAll2 = "NotsuccesAll";
          this.chartSemiSelectColor1 = this.chartSemiGreen;
          this.chartSemiSelectColor2 = this.chartSemiRed;
        } else {
          this.succesAll1 = "NotsuccesAll";
          this.succesAll2 = "succesAll";
          this.chartSemiSelectColor1 = this.chartSemiRed;
          this.chartSemiSelectColor2 = this.chartSemiGreen;
        }
        this.ChartSemi();
      });
  }

  getPhamarcyTopTenMedicineAll(idPharmacy: any) {
    var img1: string = "";
    var img2: string = "";
    var statusMore = "more";
    var statusLess = "less";
    let datagetPhamarcyTopTenMedicineAllmore: any = [];
    let datagetPhamarcyTopTenMedicineAllLess: any = [];
    ///more
    this.pharmacyService
      .GetPhamarcyTopTenMedicine_(idPharmacy, statusMore)
      .then((resgetPhamarcyTopTenMedicineAllmore) => {
        datagetPhamarcyTopTenMedicineAllmore =
          resgetPhamarcyTopTenMedicineAllmore;
        for (let i = 0; i < datagetPhamarcyTopTenMedicineAllmore.length; i++) {
          if (
            datagetPhamarcyTopTenMedicineAllmore[i].dataMedicine.medC_Image !=
            "" &&
            datagetPhamarcyTopTenMedicineAllmore[i].dataMedicine.medC_Image !=
            null
          ) {
            var imgArray =
              datagetPhamarcyTopTenMedicineAllmore[
                i
              ].dataMedicine.medC_Image.split(",");
            img1 = imgArray[0];
          }
          this.dataTopTenMore.push({
            img: img1,
            data: datagetPhamarcyTopTenMedicineAllmore[i],
          });
        }
      });
    ////less
    this.pharmacyService
      .GetPhamarcyTopTenMedicine_(idPharmacy, statusLess)
      .then((resgetPhamarcyTopTenMedicineAllLess) => {
        datagetPhamarcyTopTenMedicineAllLess =
          resgetPhamarcyTopTenMedicineAllLess;
        // this.dataTopTenLess = resgetPhamarcyTopTenMedicineAllLess;
        // console.log("this.dataTopTenLess", this.dataTopTenLess);
        for (let i = 0; i < datagetPhamarcyTopTenMedicineAllLess.length; i++) {
          if (
            datagetPhamarcyTopTenMedicineAllLess[i].dataMedicine.medC_Image !=
            "" &&
            datagetPhamarcyTopTenMedicineAllLess[i].dataMedicine.medC_Image !=
            null
          ) {
            var imgArray =
              datagetPhamarcyTopTenMedicineAllLess[
                i
              ].dataMedicine.medC_Image.split(",");
            img2 = imgArray[0];
          }
          this.dataTopTenLess.push({
            img: img2,
            data: datagetPhamarcyTopTenMedicineAllLess[i],
          });
        }
      });
  }
}
