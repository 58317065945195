<div *ngIf="getElapsedTime(timeCountdown) as elapsed">
  <div [style.display]="elapsed != '00:00' ? 'block' : 'none'">
    <div class="row" *ngIf="role != 'pharmacy'">
      <span class="label label-danger">
        <div class="timer" style="text-align:center">
          <p class="job_post" style="margin-bottom: 0px;">
            <span class="label label-danger">
              <i class="fas fa-history"></i>&nbsp;
              <b>{{elapsed}} {{datatranslatechat == "en" ? 'min':'น.'}}</b>
            </span>
          </p>
        </div>
      </span>
    </div>

    <div class="one" #one></div>
  </div>


  <div class="auth-container" [style.display]="elapsed == '00:00' ? 'block' : 'none'">
    <div class="row auth-main">
      <div class="col-sm-12 px-0">
        <div class=" left-img" style="background-image: url(assets/images/Frame1000003126.jpg)">
          <div class="form-section">
            <div class="auth-wrapper ">
              <div style="text-align: center;margin-bottom: 30px;">
                <img src="assets/images/logo/Releep-WLogo.png" alt="" width="200px">
                <h2 class="welcome-msg" style="margin-top: 20px;"><strong>{{datatranslatechat == "en" ?
                    'Thank-you':'ขอบคุณ'}}</strong></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!--For Custom Controls-->
<!-- <div class="item-center">
    <span>Custom Controls</span>
</div>
<div class="item-center"> 
    <i (click)="executeCommand('toggleAudio')" class="fas fa-2x grey-color" [ngClass]="isAudioMuted ? 'fa-microphone-slash' : 'fa-microphone'" aria-hidden="true" title="Mute / Unmute"></i>
    <i (click)="executeCommand('hangup')" class="fas fa-phone-slash fa-2x red-color" aria-hidden="true" title="Leave"></i>
    <i (click)="executeCommand('toggleVideo')" class="fas fa-2x grey-color" [ngClass]="isVideoMuted ? 'fa-video-slash' : 'fa-video'" aria-hidden="true" title="Start / Stop camera"></i>
    <i (click)="executeCommand('toggleShareScreen')" class="fas fa-film fa-2x grey-color" aria-hidden="true" title="Share your screen"></i>
</div> -->