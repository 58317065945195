<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style">
            <li class="breadcrumb-item">
              <h4 class="page-title">{{languagePharmacy.package | translate}}</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a href="javascript:void(0)">
                <i class="fas fa-home"></i>
                {{languagePharmacy.home | translate}}
              </a>
            </li>
            <li class="breadcrumb-item active">
              {{languagePharmacy.package | translate}}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="card">
          <mat-tab-group mat-align-tabs="start">
            <mat-tab label="{{languagePharmacy.allpackage | translate}}">
              <div class="body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="ngxTableHeader">
                      <ul class="header-buttons-left ms-0 mb-0">
                        <li class="dropdown">
                          <h2>
                            <strong>{{languagePharmacy.allpackage | translate}}</strong>
                          </h2>
                        </li>
                      </ul>
                    </div>
                    <!-- <table mat-table [dataSource]="packageData[0]" matSort>
                        <ng-container matColumnDef="index">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header class="fw-bold">#</th>
                          <td mat-cell *matCellDef="let i = index"><span class="fw-bold">{{i + 1}}</span></td>
                        </ng-container>
                        <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>ชื่อแพ็กเกจ</th>
                          <td mat-cell *matCellDef="let row">{{row.dataPackage.pK_Name}}</td>
                        </ng-container>
                        <ng-container matColumnDef="price">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>ราคา</th>
                          <td mat-cell *matCellDef="let row">{{row.dataPackage.pK_Price}} ฿</td>
                        </ng-container>
                        <ng-container matColumnDef="day">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>จำนวนวัน</th>
                          <td mat-cell *matCellDef="let row">{{row.dataPackage.pK_Day}}</td>
                        </ng-container>
                        <ng-container matColumnDef="remain">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>คงเหลือ</th>
                          <td mat-cell *matCellDef="let row">{{row.count}}</td>
                        </ng-container>
                        <ng-container matColumnDef="desc">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>รายละเอียด</th>
                          <td mat-cell *matCellDef="let row">{{row.dataPackage.pK_Detail}}</td>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                          <th mat-header-cell *matHeaderCellDef mat-sort-header>ขั้นตอน</th>
                          <td mat-cell *matCellDef="let row; let i=index;">
                            <button mat-icon-button color="accent" class="btn-tbl-edit">
                              <mat-icon aria-label="View" class="col-white">loyalty</mat-icon>
                            </button>
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns2;" (click)="getPackageShow(row)">
                        </tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
                  </div>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="{{languagePharmacy.mypackage | translate}}">
              <div class="body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="ngxTableHeader">
                      <ul class="header-buttons-left ms-0 mb-0">
                        <li class="dropdown">
                          <h2>
                            <strong>{{languagePharmacy.mypackage | translate}}</strong>
                          </h2>
                        </li>
                      </ul>
                      <ul class="header-buttons">
                        <li>
                          <button mat-mini-fab style="background-color: #ff7f50;">
                            <mat-icon class="col-white">add</mat-icon>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="row">
                      <!-- <div class="col-3" style="margin-bottom: 15px;" *ngFor="let row of distributorPackage">
                          <mat-card class="example-card" >
                            <mat-card-header >
                              <mat-card-title style="margin-left: -5px;">{{row.dataPackageUseToDealer.dataPackage.pK_Name}}</mat-card-title>
                            </mat-card-header>
                            <img mat-card-image src="{{urlPath}}/{{row.dataPackageUseToDealer.dataPackage.pK_Img}}" style="height: 230px;object-fit: cover;" onerror="this.src='assets/images/non-img.png'">
                            <div style="justify-content: space-between;display: flex;margin-bottom: -5px;">
                              <mat-chip-list style="margin-bottom: auto;margin-top: auto;">
                                <mat-chip style="background-color: rgba(214, 120, 70, 0.545);color: rgb(102, 72, 47);">
                                  {{row.putddT_Count}} ชิ้น
                                </mat-chip>
                              </mat-chip-list>
                              <mat-card-actions style="justify-content: end;display: flex;margin-bottom: auto;margin-top: auto;">
                              <span *ngIf="!row.disable">
                                <button mat-icon-button color="accent" class="btn-tbl-edit"
                                  (click)="$event.stopPropagation()" (click)="editPackage(row)">
                                  <mat-icon aria-label="Edit" class="col-white">loyalty</mat-icon>
                                </button>
                              </span>
                              <span *ngIf="row.disable == true">
                                <button mat-icon-button color="accent" class="btn-tbl-delete bg-danger" (click)="ablePackage(0, row)">
                                  <mat-icon aria-label="Enable" class="col-white">block</mat-icon>
                                </button>
                              </span>
                              <span *ngIf="!row.disable">
                                <button mat-icon-button color="accent" class="btn-tbl-delete bg-success" (click)="ablePackage(1, row)">
                                  <mat-icon aria-label="Disable" class="col-white">done</mat-icon>
                                </button>
                              </span>
                              </mat-card-actions>
                              </div>
                          </mat-card>
                        </div> -->
                    </div>
                    <!-- <div class="text-center mt-5" *ngIf="distributorPackage.length <= 0">
                        [ ไม่มีแพ็กเกจสำหรับผู้จัดจำหน่ายรายนี้ ]
                      </div> -->
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</section>