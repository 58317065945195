import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },

  // Admin Modules
  {
    path: "",
    title: "MENUITEMS.HOME.TEXT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin", "AdminViewOnly"],
    submenu: [
      {
        path: "/admin/dashboard/main",
        title: "MENUITEMS.HOME.LIST.DASHBOARD1",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dashboard/dashboard-doctor",
        title: "MENUITEMS.HOME.LIST.DASHBOARD-DOCTOR",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/admin/dashboard/dashboard-pharmacy",
      //   title: "MENUITEMS.HOME.LIST.DASHBOARD-PHARMACY",
      //   moduleName: "dashboard",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/dashboard/dashboard-nutritionist",
      //   title: "MENUITEMS.HOME.LIST.DASHBOARD-NUTRITIONIST",
      //   moduleName: "dashboard",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/dashboard/dashboard2",
      //   title: "MENUITEMS.HOME.LIST.DASHBOARD2",
      //   moduleName: "dashboard",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/dashboard/doctor-dashboard",
      //   title: "MENUITEMS.HOME.LIST.DOCTOR-DASHBOARD",
      //   moduleName: "doctor-dashboard",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      // {
      //   path: "/admin/dashboard/patient-dashboard",
      //   title: "MENUITEMS.HOME.LIST.PATIENT-DASHBOARD",
      //   moduleName: "patient-dashboard",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
    ],
  },

  {
    path: "",
    title: "MENUITEMS.DOCTORS.TEXT",
    moduleName: "doctors",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin", "AdminViewOnly"],
    submenu: [
      {
        path: "/admin/doctors/allDoctors",
        title: "MENUITEMS.DOCTORS.LIST.ALL-DOCTOR",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // {
  //   path: "",
  //   title: "MENUITEMS.PHARMACYS.TEXT",
  //   moduleName: "pharmacys",
  //   iconType: "material-icons-two-tone",
  //   icon: "store_mall_directory",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin", "AdminViewOnly"],
  //   submenu: [
  //     {
  //       path: "/admin/pharmacys/all-Pharmacys-list",
  //       title: "MENUITEMS.PHARMACYS.LIST.ALL-PHARMACY",
  //       moduleName: "pharmacys",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },

  {
    path: "",
    title: "MENUITEMS.ADMINVIEWONLY.TEXT",
    moduleName: "adminviewonly",
    iconType: "material-icons-two-tone",
    icon: "admin_panel_settings",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/adminview/allAdminViewOnly",
        title: "MENUITEMS.ADMINVIEWONLY.LIST.ALL-ADMINVIEWONLY",
        moduleName: "adminviewonly",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.EXECUTIVE.TEXT",
    moduleName: "Executive",
    iconType: "material-icons-two-tone",
    icon: "supervisor_account",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/executive",
        title: "MENUITEMS.EXECUTIVE.LIST.ALL-EXECUTIVES",
        moduleName: "Executive",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "ADMIN.DELIVER.DELIVER",
    moduleName: "deliver",
    iconType: "material-icons-two-tone",
    icon: "inventory_2",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/deliver/all-Deliver",
        title: "ADMIN.DELIVER.LIST-ALL-DELIVER",
        moduleName: "deliver",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  //nutritionist//
  // {
  //   path: "",
  //   title: "MENUITEMS.NUTRITONISTS.TEXT",
  //   moduleName: "nutritionist",
  //   iconType: "material-icons-two-tone",
  //   icon: "contacts",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/nutritionist/allNutritionist",
  //       title: "MENUITEMS.NUTRITONISTS.LIST.ALL-NUTRITONIST",
  //       moduleName: "nutritionist",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },

  {
    path: "",
    title: "MENUITEMS.DISTRIBUTOR.PACKAGE",
    moduleName: "package",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin", "AdminViewOnly"],
    submenu: [
      {
        path: "/admin/package/package-list",
        title: "MENUITEMS.DISTRIBUTOR.PACKAGELIST",
        moduleName: "package",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // Telsupport
  {
    path: "",
    title: "ADMIN.TELSUPPORT.TELSUPPORT",
    moduleName: "telsupport",
    iconType: "material-icons-two-tone",
    icon: "contact_phone",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin", "AdminViewOnly"],
    submenu: [
      {
        path: "/admin/telsupport/main",
        title: "ADMIN.TELSUPPORT.TELSUPPORT",
        moduleName: "telsupport",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // Package Request
  {
    path: "",
    title: "MENUITEMS.DISTRIBUTOR.PACKAGEREQUEST",
    moduleName: "packagerequest",
    iconType: "material-icons-two-tone",
    icon: "domain_verification",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin", "AdminViewOnly"],
    submenu: [
      {
        path: "/admin/packagerequest/main",
        title: "MENUITEMS.DISTRIBUTOR.PACKAGEREQUEST",
        moduleName: "packagerequest",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.MAIN.REFUND",
    moduleName: "refund",
    iconType: "material-icons-two-tone",
    icon: "monetization_on",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin", "AdminViewOnly"],
    submenu: [
      {
        path: "/admin/refund/allrefund",
        title: "MENUITEMS.MAIN.REFUND",
        moduleName: "refund",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  // dealer Modules
  // {
  //   path: "/dealer/dashboard",
  //   title: "MENUITEMS.DEALER.DASHBOARD",
  //   moduleName: "dashboard",
  //   iconType: "material-icons-two-tone",
  //   icon: "home",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Dealer"],
  //   submenu: [],
  // },
  {
    path: "/dealer/package",
    title: "MENUITEMS.DEALER.DISTRIBUTORPACKAGE",
    moduleName: "package",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Dealer"],
    submenu: [],
  },
  {
    path: "/dealer/manage-package",
    title: "MENUITEMS.DEALER.MANAGERPACKAGE",
    moduleName: "manage-package",
    iconType: "material-icons-two-tone",
    icon: "gesture",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Dealer"],
    submenu: [],
  },
  {
    path: "/dealer/dealer-report",
    title: "MENUITEMS.DEALER.REPORTPACKAGE",
    moduleName: "dealer-report",
    iconType: "material-icons-two-tone",
    icon: "chrome_reader_mode",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Dealer"],
    submenu: [],
  },
  {
    path: "/dealer/dealer-profile",
    title: "MENUITEMS.DEALER.PROFILE",
    moduleName: "manage-package",
    iconType: "material-icons-two-tone",
    icon: "person_outline",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Dealer"],
    submenu: [],
  },

  // distributor Modules
  // {
  //   path: "/distributor/dashboard",
  //   title: "MENUITEMS.DISTRIBUTOR.DASHBOARD",
  //   moduleName: "dashboard",
  //   iconType: "material-icons-two-tone",
  //   icon: "home",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Distributor"],
  //   submenu: [],
  // },
  {
    path: "/distributor/package",
    title: "MENUITEMS.DISTRIBUTOR.PACKAGE",
    moduleName: "package",
    iconType: "material-icons-two-tone",
    icon: "loyalty",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor"],
    submenu: [],
  },
  {
    path: "/distributor/packagerequest",
    title: "MENUITEMS.DISTRIBUTOR.PACKAGEREQUEST",
    moduleName: "packagerequest",
    iconType: "material-icons-two-tone",
    icon: "domain_verification",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor"],
    submenu: [],
  },
  {
    path: "/distributor/profile",
    title: "MENUITEMS.DISTRIBUTOR.SETTING",
    moduleName: "profile",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Distributor"],
    submenu: [],
  },

  // dealer Modules
  {
    path: "/audit/dashboard",
    title: "MENUITEMS.AUDIT.SIDEBAR.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  // {
  //   path: "/audit/packageAll",
  //   title: "Report Package Dealer",
  //   moduleName: "dashboard",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Audit"],
  //   submenu: [],
  // },
  {
    path: "/audit/distributor-report",
    title: "MENUITEMS.AUDIT.SIDEBAR.DISTRIBUTORREPORT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "insert_drive_file",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  {
    path: "/audit/reportDealerDistributor",
    title: "MENUITEMS.AUDIT.SIDEBAR.DEALERREPORT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "summarize",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  {
    path: "/audit/packageHistory",
    title: "MENUITEMS.AUDIT.SIDEBAR.PACKAGEHISTORY",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  {
    path: "/audit/distributorPackageRequest",
    title: "MENUITEMS.AUDIT.SIDEBAR.DISTRIBUTORPACKAGEREQUEST",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "list_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  {
    path: "/audit/dealerPackageRequest",
    title: "MENUITEMS.AUDIT.SIDEBAR.DEALERPACKAGEREQUEST",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "subtitles",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  {
    path: "/audit/userPackageActivate",
    title: "MENUITEMS.AUDIT.SIDEBAR.USERPACKAGEACTIVATE",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "fact_check",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },

  {
    path: "/audit/userPackageDeactivate",
    title: "MENUITEMS.AUDIT.SIDEBAR.USERPACKAGEDEACTIVATE",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "featured_play_list",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },
  {
    path: "/audit/OrderHistory",
    title: "AUDIT.ORDERHISTORYPAGE.ORDERHISTORYPAGE",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "add_shopping_cart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Audit"],
    submenu: [],
  },


  // {
  //   path: "",
  //   title: "MENUITEMS.PATIENTS.TEXT",
  //   moduleName: "patients",
  //   iconType: "material-icons-two-tone",
  //   icon: "face",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/patients/all-patients",
  //       title: "MENUITEMS.PATIENTS.LIST.ALL-PATIENT",
  //       moduleName: "patients",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },

  // Doctor Modules
  {
    path: "/doctor/dashboard",
    title: "MENUITEMS.DOCTOR.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/total-income",
    title: "MENUITEMS.DOCTOR.TOTAL-INCOME",
    moduleName: "income",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/appointments",
    title: "MENUITEMS.DOCTOR.REQUEST-APPOINTMENT",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/appointments-calendar",
    title: "MENUITEMS.DOCTOR.CALENDAR-APPOINTMENT",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "event_available",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/doctor-articles",
    title: "MENUITEMS.DOCTOR.DOCTOR-ARTICLES",
    moduleName: "articles",
    iconType: "material-icons-two-tone",
    icon: "notes",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/patients",
    title: "MENUITEMS.DOCTOR.PATIENTS",
    moduleName: "patients",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/patients-information-all",
    title: "DOCTOR.PATIENTSALL.PATIENTSALL",
    moduleName: "patientsall",
    iconType: "material-icons-two-tone",
    icon: "assignment_ind",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  {
    path: "/doctor/doctor-chats",
    title: "MENUITEMS.DOCTOR.CHAT",
    moduleName: "chat",
    iconType: "material-icons-two-tone",
    icon: "chat",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Doctor"],
    submenu: [],
  },
  // {
  //   path: "/doctor/settings",
  //   title: "MENUITEMS.DOCTOR.SETTINGS",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },

  // Nutritionist Modules
  {
    path: "/nutritionist/dashboard",
    title: "MENUITEMS.NUTRITONIST.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },
  {
    path: "/nutritionist/appointments",
    title: "MENUITEMS.NUTRITONIST.REQUEST-APPOINTMENT",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },
  {
    path: "/nutritionist/appointments-calendar",
    title: "MENUITEMS.NUTRITONIST.CALENDAR-APPOINTMENT",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "event_available",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },
  {
    path: "/nutritionist/nutritionist-articles",
    title: "MENUITEMS.NUTRITONIST.NUTRITONIST-ARTICLES",
    moduleName: "articles",
    iconType: "material-icons-two-tone",
    icon: "notes",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },
  {
    path: "/nutritionist/come-to-consult",
    title: "MENUITEMS.NUTRITONIST.PATIENTS",
    moduleName: "patients",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },
  {
    path: "/nutritionist/nutritionist-chats",
    title: "MENUITEMS.NUTRITONIST.CHAT",
    moduleName: "chat",
    iconType: "material-icons-two-tone",
    icon: "chat",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },
  {
    path: "/nutritionist/total-income",
    title: "MENUITEMS.NUTRITONIST.TOTAL-INCOME",
    moduleName: "income",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Nutritionist"],
    submenu: [],
  },

  // Executive

  {
    path: "/executive/dashboard",
    title: "MENUITEMS.DOCTOR.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Executive"],
    submenu: [],
  },
  {
    path: "/executive/user-report",
    title: "MENUITEMS.EXECUTIVE.LIST.USERS-REPORT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "group",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Executive"],
    submenu: [],
  },
  {
    path: "/executive/doctor-income-report",
    title: "MENUITEMS.EXECUTIVE.LIST.DOCTOR-INCOME-REPORT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "insert_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Executive"],
    submenu: [],
  },
  {
    path: "/executive/doctor-case-report",
    title: "MENUITEMS.EXECUTIVE.LIST.DOCTOR-CASE-REPORT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "business_center",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Executive"],
    submenu: [],
  },
  {
    path: "/executive/package-order-report",
    title: "MENUITEMS.EXECUTIVE.LIST.PACKAGE-ORDER-REPORT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "list_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Executive"],
    submenu: [],
  },

  // Pharmacy Modules
  {
    path: "/pharmacy/dashboard",
    title: "MENUITEMS.PHARMACY.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Pharmacy"],
    submenu: [],
  },
  {
    path: "/pharmacy/drug-list",
    title: "MENUITEMS.PHARMACY.DRUG-LIST",
    moduleName: "drug-list",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Pharmacy"],
    submenu: [],
  },
  {
    path: "/pharmacy/order-drug-list",
    title: "MENUITEMS.PHARMACY.ORDER-DRUG-LIST",
    moduleName: "orderdrug",
    iconType: "material-icons-two-tone",
    icon: "local_grocery_store",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Pharmacy"],
    submenu: [],
  },
  {
    path: "/pharmacy/pharmacy-chat",
    title: "MENUITEMS.PHARMACY.PHARMACY-CHAT",
    moduleName: "chat",
    iconType: "material-icons-two-tone",
    icon: "chat",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Pharmacy"],
    submenu: [],
  },
  {
    path: "/pharmacy/total-income",
    title: "MENUITEMS.PHARMACY.TOTAL-INCOME",
    moduleName: "totalincome",
    iconType: "material-icons-two-tone",
    icon: "bar_chart",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Pharmacy"],
    submenu: [],
  },
  // {
  //   path: "/pharmacy/package",
  //   title: "MENUITEMS.DISTRIBUTOR.PACKAGE",
  //   moduleName: "package",
  //   iconType: "material-icons-two-tone",
  //   icon: "loyalty",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Pharmacy"],
  //   submenu: [],
  // },
  // {
  //   path: "/pharmacy/settings",
  //   title: "MENUITEMS.PHARMACY.PHARMACY-SETTINGS",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Pharmacy"],
  //   submenu: [],
  // },

  // Patient Modules
  {
    path: "/patient/dashboard",
    title: "MENUITEMS.PATIENT.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "home",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  {
    path: "",
    title: "MENUITEMS.PATIENT.APPOINTMENTS.TEXT",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [
      {
        path: "/patient/appointments/today",
        title: "MENUITEMS.PATIENT.APPOINTMENTS.LIST.TODAY",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/appointments/upcoming",
        title: "MENUITEMS.PATIENT.APPOINTMENTS.LIST.UPCOMING",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/appointments/past",
        title: "MENUITEMS.PATIENT.APPOINTMENTS.LIST.PAST",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "/patient/prescriptions",
    title: "MENUITEMS.PATIENT.PRESCRIPTIONS",
    moduleName: "prescriptions",
    iconType: "material-icons-two-tone",
    icon: "receipt_long",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  {
    path: "/patient/records",
    title: "MENUITEMS.PATIENT.MEDICAL-RECORD",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "restore_page",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  {
    path: "/patient/billing",
    title: "MENUITEMS.PATIENT.BILLING",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "receipt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  {
    path: "/apps/chat",
    title: "MENUITEMS.PATIENT.CHAT",
    moduleName: "apps",
    iconType: "material-icons-two-tone",
    icon: "chat",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  {
    path: "/patient/settings",
    title: "MENUITEMS.PATIENT.SETTINGS",
    moduleName: "settings",
    iconType: "material-icons-two-tone",
    icon: "settings",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Patient"],
    submenu: [],
  },
  // {
  //   path: "calendar",
  //   title: "Calendar",
  //   moduleName: "calendar",
  //   iconType: "material-icons-two-tone",
  //   icon: "event_note",
  //   class: "",
  //   groupTitle: false,
  //   badge: "New",
  //   badgeClass: "badge bg-blue sidebar-badge float-end",
  //   role: ["Admin"],
  //   submenu: [],
  // },

  // Start telSupport //
  {
    path: "/telSupport/main",
    title: "ADMIN.TELSUPPORT.TELSUPPORTCALENDAR",
    moduleName: "calendartelsupport",
    iconType: "material-icons-two-tone",
    icon: "contact_phone",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["TelSupport"],
    submenu: [],
  },
  {
    path: "/telSupport/listCloseCase",
    title: "ADMIN.TELSUPPORT.LISTCLOSETHECASEAVE",
    moduleName: "calendartelsupport",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["TelSupport"],
    submenu: [],
  },

  // {
  //   path: "/telSupport/list",
  //   title: "List Tel Support",
  //   moduleName: "telsupport",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["TelSupport"],
  //   submenu: [],
  // },
  // End telSupport //

  // Start packer //
  {
    path: "/deliver/main",
    title: "ADMIN.DELIVER.LIST-DELIVERY",
    moduleName: "deliver",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Packer"],
    submenu: [],
  },
  // End packer //
];
