<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style ">
            <li class="breadcrumb-item">
              <h4 class="page-title">{{languagePharmacy.orderdruglist | translate}}</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a href="javascript:void(0)">
                <i class="fas fa-home"></i> {{languagePharmacy.home | translate}}</a>
            </li>
            <li class="breadcrumb-item active">{{languagePharmacy.orderdruglist | translate}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="newOrder == true" class="fadeInDown alert alert-warning d-flex align-items-center"
      style="background-color:rgb(245 156 14 / 47%);" role="alert">
      <i class="fas fa-exclamation-circle"></i>&nbsp;
      <div style="color: white;">
        {{languagePharmacy.anorderhasbeenplaced | translate}} {{newOrderCount}}
        {{languagePharmacy.orderpleasereloadnewpage | translate}}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="card">
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-3 col-12">
              <mat-form-field appearance="outline" style="width: 250px;margin-top: 20px;margin-left: 20px;">
                <mat-label>{{languagePharmacy.enterdaterange | translate}}</mat-label>
                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="{{languagePharmacy.starddate | translate}}">
                  <input matEndDate formControlName="end" placeholder="{{languagePharmacy.enddate | translate}}"
                    (dateChange)="onChengDate()">
                </mat-date-range-input>

                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-xl-5 col-lg-3 col-md-7 col-sm-5 col-12 showDateYext"
              style="margin-top: 10px;text-align: center;">
              <h2 *ngIf="newStartDate == null" id="textDate">{{languagePharmacy.dateinformation | translate}} :
                {{dateNow | date:'mediumDate'}}</h2>
              <h2 *ngIf="newStartDate != null" id="textDate">{{languagePharmacy.dateinformation | translate}} :
                <span>{{newStartDate | date:'mediumDate'}} - <br>
                  {{newStartDate | date:'mediumDate'}}
                </span>
              </h2>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-5 col-sm-3 col-9 typeOrder">
              <form [formGroup]="optionForm">
                <mat-radio-group aria-label="Select an option" style="float: right;display: flex;"
                  formControlName="options">
                  <mat-radio-button value="1" (change)="onChangeStatus('1')">{{languagePharmacy.waiting | translate}}
                  </mat-radio-button>
                  <mat-radio-button value="2" (change)="onChangeStatus('2')">{{languagePharmacy.successful | translate}}
                  </mat-radio-button>
                  <mat-radio-button value="3" (change)="onChangeStatus('3')">{{languagePharmacy.all | translate}}
                  </mat-radio-button>
                </mat-radio-group>
              </form>
            </div>
            <div class="col-xl-1 col-lg-1 col-md-2 col-sm-1 col-3">
              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                class="orderPending">
                <span class="mat-button-wrapper" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="Pending Orders">
                  <mat-icon _ngcontent-nvp-c192="" role="img" matbadgecolor="warn"
                    class="mat-icon notranslate mat-badge mat-badge-warn material-icons mat-icon-no-color mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium"
                    aria-hidden="true" ng-reflect-color="warn" ng-reflect-content="0" data-mat-icon-type="font"
                    style="font-size: 40px;padding-right: 38px;">
                    shopping_basket <span id="mat-badge-content-0" *ngIf="countOrder > 0" aria-hidden="true"
                      class="mat-badge-content mat-badge-active">{{countOrder}}</span></mat-icon>
                </span>
              </button>
              <mat-menu #menu="matMenu">
                <div class="row" *ngFor="let itemPhamacyRequest of dataGetOrderPhamacyRequests"
                  style="width: 270px;font-size: 12px;margin: 5px;" id="orderOld"
                  (click)="onclickShowDataPhamacyRequest(itemPhamacyRequest.idOrder)">
                  <!-- <div style="display: flex;"> -->
                  <div class="col-xl-3 mt-2" style="padding-top: 10px;text-align: center;">
                    <img src="{{urlPath2021}}{{itemPhamacyRequest.dataUser.user_Pic}}" alt=""
                      onerror="this.src='assets/images/imgdoctor/nouser.svg'"
                      style="width: 35px;height:35px;border-radius: 10px;object-fit: cover;">
                  </div>
                  <div class="col-xl-9 mt-2" style="padding-top: 10px;">
                    <p style="line-height: 3px;">{{languagePharmacy.orderdate | translate}} :
                      <b>{{itemPhamacyRequest.ordeR_Date |
                        date:'dd/MM/YYYY'}}</b>
                    </p>
                    <p style="line-height: 2px;">{{languagePharmacy.quantity | translate}} :
                      <b>{{itemPhamacyRequest.orderList.length}} {{languagePharmacy.list | translate}} </b>
                    </p>
                    <p style="line-height: 5px;">{{languagePharmacy.total | translate}} :
                      <b>฿&nbsp;{{itemPhamacyRequest.dataReceipt.recpT_TotalPrice}}</b>
                    </p>
                  </div>
                  <!-- </div> -->
                </div>
              </mat-menu>
            </div>
          </div>
          <hr style="margin-top: 0px;margin-bottom: 0px">
          <div class="table-responsive">
            <table mat-table *ngIf="dataLoading == false" [dataSource]="dataSource" multiTemplateDataRows matSort>

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.id | translate}} </th>
                <td mat-cell *matCellDef="let element">{{element.dataReceipt.recpT_No}}</td>
              </ng-container>

              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.image | translate}} </th>
                <td mat-cell class="table-img padding-0" *matCellDef="let element">
                  <img width="40" src="{{urlPath2021}}{{element.dataUser.user_Pic}}" style="object-fit: cover;"
                    onerror="this.src='assets/images/imgdoctor/nouser.svg'" />
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.name | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.dataUser.user_Name}}</td>
              </ng-container>

              <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.time | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{ element.ordeR_Date |
                  date:'medium'}}
                </td>
              </ng-container>

              <ng-container matColumnDef="orderList">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{languagePharmacy.orderlist | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.orderList.length}} {{languagePharmacy.list |
                  translate}}</td>
              </ng-container>

              <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.typ | translate}} </th>
                <td mat-cell *matCellDef="let element">
                  <div class="badge col-orange"
                    *ngIf="element.dataReceipt.recpT_TrackingNo == null || element.dataReceipt.recpT_TrackingNo == ''">
                    {{languagePharmacy.waitingfordelivery | translate}}</div>
                  <div class="badge col-green"
                    *ngIf="element.dataReceipt.recpT_TrackingNo != null && element.dataReceipt.recpT_TrackingNo != ''">
                    {{languagePharmacy.successfuldelivery | translate}}</div>&nbsp; &nbsp;
                  <div class="badge col-red"
                    *ngIf="element.dataReceipt.recpT_TransferStutus == false && element.dataReceipt.recpT_TypePayment == 'transfer'">
                    {{languagePharmacy.failedtransferstatus | translate}}</div>
                  <div class="badge col-green"
                    *ngIf="element.dataReceipt.recpT_TransferStutus == true && element.dataReceipt.recpT_TypePayment == 'transfer'">
                    {{languagePharmacy.transferstatussuccessful | translate}}</div>
                  <div class="badge col-gray"
                    *ngIf="element.dataReceipt.recpT_TransferStutus == null && element.dataReceipt.recpT_TypePayment == 'transfer'">
                    {{languagePharmacy.checkthetransferstatus | translate}}</div>
                </td>
              </ng-container>

              <ng-container matColumnDef="typePayment">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{languagePharmacy.typepayment | translate}} </th>
                <td mat-cell *matCellDef="let element">
                  <h6 *ngIf="element.dataReceipt.recpT_TypePayment != 'transfer'">
                    {{languagePharmacy.payviacredit | translate}}</h6>
                  <h6 *ngIf="element.dataReceipt.recpT_TypePayment == 'transfer'">{{languagePharmacy.banktransfer |
                    translate}}
                  </h6>
                </td>
              </ng-container>

              <ng-container matColumnDef="more">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.more | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-icon-button color="accent" class="btn-tbl-delete">
                    <span class="material-icons-outlined" style="color: white;">arrow_drop_down</span>
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="displayedColumns">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                  <div class="example-element-detail"
                    [@detailExpand]="element == expandedElementList1 ? 'expanded' : 'collapsed'">
                    <mat-card class="example-card" class="example-full-width"
                      style="margin: 20px;width: 800px;height: 560px;">
                      <h4 *ngIf="element.dataReceipt.recpT_TypePayment == 'transfer'">
                        {{languagePharmacy.listofallproducts | translate}}
                        <button *ngIf="element.dataReceipt.recpT_TransferStutus  == null" mat-stroked-button
                          color="warn" (click)="dialogPayment(element,sumqty)" style="float: right;">
                          {{languagePharmacy.checkthetransferstatus | translate}}</button>
                        <div *ngIf="element.dataReceipt.recpT_TransferStutus  == true" class="badge col-green"
                          (click)="dialogPayment(element,sumqty)" style="float: right;">
                          {{languagePharmacy.transferstatussuccessful | translate}}</div>
                        <button *ngIf="element.dataReceipt.recpT_TransferStutus  == false" mat-stroked-button
                          color="warn" (click)="dialogPayment(element,sumqty)" style="float: right;">
                          {{languagePharmacy.failedtransferstatus | translate}}</button>
                      </h4>
                      <h4 *ngIf="element.dataReceipt.recpT_TypePayment == 'internet_banking_bbl'">
                        {{languagePharmacy.listofallproducts | translate}}
                        <div class="badge col-green" style="float: right;">โอนเงินผ่าน Internet Banking(BBL)</div>
                      </h4>
                      <h4 *ngIf="element.dataReceipt.recpT_TypePayment == 'internet_banking_bay'">
                        {{languagePharmacy.listofallproducts | translate}}
                        <div class="badge col-green" style="float: right;">โอนเงินผ่าน Internet Banking(BAY)</div>
                      </h4>
                      <div class="fixed-scroll-drug">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2" style="padding-top: 10px;"
                          *ngFor="let Order of element.orderList">
                          <li class="patient-group-list" (click)="showOrderByListId(Order.medC_)" id="orderlist">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                              <div class="group-details font-18"><b>{{Order.medC_.medC_Name}}</b></div>
                              <div class="group-details font-13" style="line-height: 10px;">{{languagePharmacy.quantity
                                | translate}}
                                <b>{{Order.qty}}</b> {{languagePharmacy.pieces | translate}}
                              </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6"
                              style="text-align: end;padding-right: 10px;">
                              <div class="media-title mb-0 font-13" style="color: black;">{{languagePharmacy.price |
                                translate}}
                                <b>{{Order.medC_.medC_Price}}</b> {{languagePharmacy.baht | translate}}
                              </div>
                              <div class="media-title mb-0 font-13" style="color: black;">{{languagePharmacy.totalprice
                                | translate}}
                                <b>{{Order.medC_.medC_Price * Order.qty}}</b> {{languagePharmacy.baht | translate}}
                              </div>
                            </div>
                          </li>
                          <hr style="top: 0;">
                        </div>
                      </div>
                      <p style="text-align: end;padding-right: 25px;">{{languagePharmacy.totalpriceall | translate}}
                        {{sumqty}} {{languagePharmacy.baht | translate}}</p>
                      <div class="footer">
                        <div class="row">
                          <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                            <strong
                              *ngIf="element.dataReceipt.recpT_ShippingCompany != null && element.dataReceipt.recpT_ShippingCompany != ''">{{element.dataReceipt.recpT_ShippingCompany}}</strong>
                            <strong
                              *ngIf="element.dataReceipt.recpT_ShippingCompany == null || element.dataReceipt.recpT_ShippingCompany == ''"
                              style="color: red;">* {{languagePharmacy.parceltracking | translate}}</strong>
                            <p
                              *ngIf="element.dataReceipt.recpT_TrackingNo != null && element.dataReceipt.recpT_TrackingNo != ''">
                              {{element.dataReceipt.recpT_TrackingNo}}</p>
                            <p
                              *ngIf="element.dataReceipt.recpT_TrackingNo == null || element.dataReceipt.recpT_TrackingNo == ''">
                              -</p>
                          </div>
                          <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                            <div *ngIf="element.dataReceipt.recpT_TypePayment == 'transfer'">
                              <button mat-raised-button type="submit"
                                disabled="{{!element.dataReceipt.recpT_TransferStutus}}"
                                (click)="editDialog(element.idOrder,element.dataReceipt,false)"
                                style="background-color: #ff7f50;color: white;"> {{languagePharmacy.update |
                                translate}}</button>
                            </div>
                            <div *ngIf="element.dataReceipt.recpT_TypePayment != 'transfer'">
                              <button mat-raised-button type="submit"
                                (click)="editDialog(element.idOrder,element.dataReceipt,false)"
                                style="background-color: #ff7f50;color: white;">{{languagePharmacy.update |
                                translate}}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                    <mat-card class="example-card" class="example-full-width" *ngIf="showOrderListById != null"
                      style="margin: 20px;box-shadow: rgba(136, 136, 136, 0.25) 0px 50px 100px -20px, rgba(124, 124, 124, 0.3) 0px 30px 60px -30px,rgba(136, 136, 136, 0.25) 0px -2px 6px 0px inset;">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2" style="z-index: 0;">
                          <div style="margin-bottom: 8px;text-align: center">
                            <img src="{{urlPath}}/{{imgF}}" style="max-height: 300px;"
                              onerror="this.src='assets/images/404/404error_2.png'">
                          </div>
                          <carousel>
                            <div class="carousel-cell" *ngFor='let imgDrugList of imgDrugLists'
                              (mouseenter)="imgClicked(imgDrugList)">
                              <img class="border-select" src="{{urlPath}}/{{imgDrugList}}" style="object-fit: cover;"
                                onerror="this.src='assets/images/404/404error_2.png'">
                            </div>
                          </carousel>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
                          <mat-card class="mat-elevation-z8"
                            style="height: 530px;overflow-x: hidden;overflow-y: scroll;">
                            <div style="margin-top:20px;font-size:18px;text-align: start;">
                              <strong>{{showOrderListById.medC_Name}}</strong>&nbsp;
                              <!-- <p style="overflow: hidden;font-size: 14px;color: rgb(15, 94, 15);">
                                {{showOrderListById.medC_Description}}</p> -->
                              <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                  <mat-panel-title style="padding-top: 12px;">
                                    <p
                                      style="width: 210px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: rgb(15, 94, 15);">
                                      {{showOrderListById.medC_Description}}</p>
                                  </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p style="overflow: hidden;font-size: 14px;color: rgb(15, 94, 15);">
                                  {{showOrderListById.medC_Description}}</p>
                              </mat-expansion-panel>
                            </div>

                            <!-- <div style="margin-top:3px;font-size:18px;text-align: start;">
                                <span class="badge bg-danger">ExpirationDate : {{showOrderListById.medC_ExpirationDate
                                  |
                                  date: 'dd/MM/YYYY'}}</span>
                              </div> -->
                            <div
                              style="margin-top:15px;font-size:18px;text-align: start; background-color:#1a202e0f;padding-top:5px;margin-bottom:5px;padding: 6px;;">
                              <strong>{{languagePharmacy.productdetails | translate}}</strong>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                              <span style="margin-right: 40px;">{{languagePharmacy.discount | translate}}</span> :&nbsp;
                              <div class="contact-detail-info" style="text-align:start">
                                <b>{{showOrderListById.medC_Discount}}</b>
                              </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                              <span style="margin-right: 63px;">{{languagePharmacy.price | translate}}</span> :&nbsp;
                              <div class="contact-detail-info" style="text-align:start">
                                <b>฿ {{showOrderListById.medC_Price}}</b>
                              </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                              <span style="margin-right: 74px;">{{languagePharmacy.qty | translate}}</span> :&nbsp;
                              <div class="contact-detail-info" style="text-align:start">
                                <b>{{showOrderListById.medC_Qty}}
                                  {{showOrderListById.medC_Unit}}</b>
                              </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                              <span style="margin-right: 5px;">{{languagePharmacy.medcname | translate}}</span> :&nbsp;
                              <div class="contact-detail-info" style="text-align:start">
                                <b>{{showOrderListById.medC_MedicalName}}</b>
                              </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                              <span style="margin-right: 46px;">{{languagePharmacy.caution | translate}}</span> :&nbsp;
                              <div class="contact-detail-info" style="text-align:start">
                                <b>{{showOrderListById.medC_Caution}}</b>
                              </div>
                            </div>
                            <div class="contact-details-field" style="margin-top:15px">
                              <span style="margin-right: 27px;">{{languagePharmacy.howtouse | translate}}</span> :&nbsp;
                              <div class="contact-detail-info" style="text-align:start">
                                <b>{{showOrderListById.medC_HowToUse}}</b>
                              </div>
                            </div>
                          </mat-card>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="example-element-row"
                [class.example-expanded-row]="expandedElementList1 === element"
                (click)="expandedElementList1 = expandedElementList1 === element ? null : element"
                (click)="showOrderByListId(element.orderList[0].medC_)" (click)="sumQty(element.orderList)">
              </tr>
              <tr mat-row *matRowDef="let row; columns: ['displayedColumns']" class="example-detail-row"></tr>
            </table>
            <div *ngIf="!dataLoading && dataOrderAll.length <= 0 && dataTrackingRequest.length <= 0"
              style="text-align:center;margin-top: 70px ;">
              <h4 style="color:gray;"><i class="far fa-calendar-times"></i> {{languagePharmacy.nodata | translate}} !
              </h4>
            </div>
            <img *ngIf="dataLoading" class="text-center d-block border-top py-1"
              src="assets/images/loading/1_CsJ05WEGfunYMLGfsT2sXA.gif" style="width:220px;margin: auto;" alt="loading">
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users">
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>