<section class="content">
    <div class="container-fluid">
        <div class="block-header">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <ul class="breadcrumb breadcrumb-style ">
                        <li class="breadcrumb-item">
                            <h4 class="page-title">{{languagePharmacy.ti_totalincome | translate}}</h4>
                        </li>
                        <li class="breadcrumb-item bcrumb-1">
                            <a href="javascript:void(0)">
                                <i class="fas fa-home"></i> {{languagePharmacy.home | translate}}</a>
                        </li>
                        <li class="breadcrumb-item bcrumb-2">
                            <a href="javascript:void(0)">{{languagePharmacy.pharmacy | translate}}</a>
                        </li>
                        <li class="breadcrumb-item active">{{languagePharmacy.ti_totalincome | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xl-5 col-xs-12 col-sm-12 col-md-12 col-lg-4 col-12">
                <div class="row">
                    <div class="col-xl-6 col-xs-6 col-sm-12 col-md-6 col-lg-6 col-12">
                        <div class="card card-statistic-2">
                            <div class="clearfix">
                                <div class="card-icon-only shadow-primary float-start deleteImg">
                                    <img src="assets/imgMoney/money.jpg" alt="" width="38">
                                </div>
                                <div class="card-right">
                                    <div>
                                        <h5 class="float-end" style="font-size: 15px;">{{languagePharmacy.monthincome |
                                            translate}}</h5>
                                    </div>
                                    <div class="col-green">
                                        <h5 class="font-weight-bold float-end mb-0">฿ {{numMoneyMonth | number}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xs-6 col-sm-12 col-md-6 col-lg-6 col-12">
                        <div class="card card-statistic-2">
                            <div class="clearfix">
                                <div class="card-icon-only shadow-primary float-start deleteImg">
                                    <img src="assets/imgMoney/moneyAll.png" alt="" width="40">
                                </div>
                                <div class="card-right">
                                    <div>
                                        <h5 style="font-size: 15px;" class="float-end">{{languagePharmacy.ti_totalincome
                                            | translate}}</h5>
                                    </div>
                                    <div class="col-green">
                                        <h5 class="font-weight-bold float-end mb-0">฿ {{numMoneyAll | number}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xs-6 col-sm-12 col-md-6 col-lg-6 col-12">
                        <div class="card card-statistic-2">
                            <div class="clearfix">
                                <div class="card-icon-only shadow-primary float-start deleteImg">
                                    <img src="assets/imgMoney/box.png" alt="" width="40">
                                </div>
                                <div class="card-right">
                                    <div>
                                        <h5 style="font-size: 15px;" class="float-end">{{languagePharmacy.ti_monthorder
                                            | translate}}</h5>
                                    </div>
                                    <div class="col-green">
                                        <h5 class="font-weight-bold float-end mb-0">{{countOrderMonth | number}}
                                            {{languagePharmacy.ti_order | translate}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-xs-6 col-sm-12 col-md-6 col-lg-6 col-12">
                        <div class="card card-statistic-2">
                            <div class="clearfix">
                                <div class="card-icon-only shadow-primary float-start deleteImg">
                                    <img src="assets/imgMoney/box.png" alt="" width="40">
                                </div>
                                <div class="card-right">
                                    <div>
                                        <h5 style="font-size: 15px;" class="float-end">{{languagePharmacy.ti_allorder |
                                            translate}}</h5>
                                    </div>
                                    <div class="col-green">
                                        <h5 class="font-weight-bold float-end mb-0">{{countOrderAll | number}}
                                            {{languagePharmacy.ti_order | translate}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <h4 style="margin: 10px;">{{languagePharmacy.ti_todayincomeandorsers | translate}}</h4>
                    <div class="row">
                        <div class="col-xl-6 col-xs-12 col-sm-6 col-md-6 col-lg-12 col-12">
                            <div class="total-BackMoney">
                                <div style="text-align: center;margin-top: 30px;margin-bottom: 50px;">
                                    <p style="font-size: 14px;">{{languagePharmacy.ti_netincome | translate}}</p>
                                    <p style="font-size: 25px;line-height: 10px;"><b>฿ {{numMoneyDay | number}}</b></p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-xs-12 col-sm-6 col-md-6 col-lg-12 col-12">
                            <div class="total-BackOrder">
                                <div style="text-align: center;margin-top: 30px;margin-bottom: 50px;">
                                    <p style="font-size: 14px;">{{languagePharmacy.ti_ordertoday | translate}}</p>
                                    <p style="font-size: 25px;line-height: 8px;">
                                        <i class="fas fa-shopping-cart"></i>&nbsp;<b>{{countOrderDay | number}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin: 10px;">
                        <h5>{{languagePharmacy.ti_incomeinfo | translate}}</h5>
                        <div class="col-12  text-center mt-5" *ngIf="dataLoadingIncomeDay">
                            <div class="preloader">
                                <div class="spinner-layer pl-black">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive m-t-15" style="position: relative; max-height: 367px;"
                            [perfectScrollbar] *ngIf="!dataLoadingIncomeDay">
                            <table class="table align-items-center">
                                <tbody>
                                    <tr *ngFor="let tracking of dataTotalIncomeDay">
                                        <!-- <tr *ngIf="tracking.recpT_TransferStutus == true || tracking.recpT_TypePayment == 'digipay' && tracking.recpT_TransferStutus == true"> -->
                                        <td
                                            *ngIf="tracking.recpT_TypePayment == 'transfer' && tracking.recpT_TransferStutus == true || tracking.recpT_TypePayment == 'digipay' && tracking.recpT_StatusPay == true">
                                            <i class="fa fa-circle col-green msr-2"></i>{{tracking.ordeR_Date |
                                            timeago:live}}
                                        </td>
                                        <td *ngIf="tracking.recpT_TypePayment == 'transfer' && tracking.recpT_TransferStutus == true || tracking.recpT_TypePayment == 'digipay' && tracking.recpT_StatusPay == true"
                                            style="color: green;">{{tracking.recpT_TypePayment}}</td>
                                        <td
                                            *ngIf="tracking.recpT_TypePayment == 'transfer' && tracking.recpT_TransferStutus == true || tracking.recpT_TypePayment == 'digipay' && tracking.recpT_StatusPay == true">
                                            <b>฿ {{tracking.recpT_TotalPrice}}</b>
                                        </td>
                                        <td *ngIf="tracking.recpT_TypePayment == 'transfer' && tracking.recpT_TransferStutus == true || tracking.recpT_TypePayment == 'digipay' && tracking.recpT_StatusPay == true"
                                            class="col-green">{{tracking.orderList.length}} {{languagePharmacy.ti_order
                                            | translate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div *ngIf="dataTotalIncomeDay == 0 || numMoneyDay == 0" style="text-align: center;">
                                <h5 style="color:gray;"><i class="far fa-calendar-times"></i>
                                    {{languagePharmacy.ti_nodata | translate}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-7 col-xs-12 col-sm-12 col-md-12 col-lg-8 col-12">
                <div class="card">
                    <div class="header">
                        <h2>{{languagePharmacy.ti_annualincome | translate}} {{yearNow}}</h2>
                    </div>
                    <div class="body">
                        <div class="col-12  text-center mt-5" *ngIf="dataLoadingIncomeToYear">
                            <div class="preloader">
                                <div class="spinner-layer pl-black">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="text-align:center" *ngIf="!dataLoadingIncomeToYear">
                            <apx-chart [series]="barChartTotelIncome.series" [chart]="barChartTotelIncome.chart"
                                [dataLabels]="barChartTotelIncome.dataLabels"
                                [plotOptions]="barChartTotelIncome.plotOptions" [yaxis]="barChartTotelIncome.yaxis"
                                [xaxis]="barChartTotelIncome.xaxis" [fill]="barChartTotelIncome.fill"
                                [title]="barChartTotelIncome.title" [colors]="barChartTotelIncome.colors"></apx-chart>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="header">
                        <h2>{{languagePharmacy.ti_ordersperyear | translate}} {{yearNow}}</h2>
                    </div>
                    <div class="body">
                        <div class="col-12  text-center mt-5" *ngIf="dataLoadingIncomeToYear">
                            <div class="preloader">
                                <div class="spinner-layer pl-black">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="text-align:center" *ngIf="!dataLoadingIncomeToYear">
                            <apx-chart [series]="barChartOrderAll.series" [chart]="barChartOrderAll.chart"
                                [dataLabels]="barChartOrderAll.dataLabels" [plotOptions]="barChartOrderAll.plotOptions"
                                [yaxis]="barChartOrderAll.yaxis" [xaxis]="barChartOrderAll.xaxis"
                                [fill]="barChartOrderAll.fill" [title]="barChartOrderAll.title"
                                [colors]="barChartOrderAll.colors"></apx-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-12">
                <div class="card">
                    <div class="row">
                        <div class="col-xl-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-12">
                            <div class="header" style="margin-top: 20px;">
                                <h2><b>{{languagePharmacy.ti_history | translate}}</b></h2>
                            </div>
                        </div>
                        <div class="col-xl-5 col-xs-5 col-sm-5 col-md-5 col-lg-5 col-10">
                            <mat-form-field appearance="outline"
                                style="width: 250px;margin-top: 20px;float: right;margin-right: 20px;">
                                <mat-label>{{languagePharmacy.ti_daterange | translate}}</mat-label>
                                <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                                    <input matStartDate formControlName="start" placeholder="Start date">
                                    <input matEndDate formControlName="end" placeholder="End date"
                                        (dateChange)="onChengDate()">
                                </mat-date-range-input>

                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker></mat-date-range-picker>
                            </mat-form-field>
                        </div>
                        <div class="col-xl-1 col-xs-1 col-sm-1 col-md-1 col-lg-1 col-2" style="margin: auto;">
                            <div class="example-button-container">
                                <button mat-mini-fab style="background-color: #ff7f50;" (click)="reloadDate()">
                                    <mat-icon>autorenew</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <hr style="margin:0px;">
                    <div class="table-responsive">
                        <div class="col-12  text-center mt-5" *ngIf="dataLoading">
                            <div class="preloader">
                                <div class="spinner-layer pl-black">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table mat-table *ngIf="!dataLoading" [dataSource]="dataSource" matSort>
                            <ng-container matColumnDef="idOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.ti_idorder |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let row">
                                    <p>{{row.ordeR_Id}}</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="time">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.ti_time |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let row">
                                    <p>{{row.ordeR_Date | date:'medium'}}</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="orderList">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.ti_orderlist |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let row">
                                    <p>{{row.orderList.length}} {{languagePharmacy.ti_order | translate}}</p>
                                    <!-- <p *ngIf="row.dataConversation.convO_Time != null">row.dataConversation.convO_Time Min</p> -->
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="price">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.ti_price |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let row">
                                    <p>{{row.recpT_TotalPrice}} ฿</p>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="payment">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{languagePharmacy.ti_payment |
                                    translate}} </th>
                                <td mat-cell *matCellDef="let row">
                                    <div style="color: green;">{{row.recpT_TypePayment}}</div>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="transferStutus">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    {{languagePharmacy.ti_transferstatus | translate}} </th>
                                <td mat-cell *matCellDef="let row">
                                    <div *ngIf="row.recpT_TransferStutus == true && row.recpT_TypePayment == 'transfer'
                                    || row.recpT_StatusPay == true && row.recpT_TypePayment == 'digipay'"
                                        style="color: green;font-size: 21px;">
                                        <i class="far fa-check-circle"></i>
                                    </div>
                                    <div *ngIf="row.recpT_TransferStutus == false && row.recpT_TypePayment == 'transfer'
                                    || row.recpT_StatusPay == false && row.recpT_TypePayment == 'digipay'"
                                        style="color: red;font-size: 21px;">
                                        <i class="far fa-times-circle"></i>
                                    </div>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="detailsTotalIncome(row)">
                            </tr>

                        </table>
                    </div>
                    <mat-paginator [pageSizeOptions]="[5,10, 25, 50 , 100]" aria-label="Select page of users">
                    </mat-paginator>
                </div>
            </div>
        </div>
    </div>
</section>