<div class="addContainer" style="min-width: 262px;">
    <div class="row">
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 marginImg">
            <img src="assets/images/digit-w.gif" alt="" class="widhimg">
        </div>
        <div class="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
            <h2 style="text-align: center;margin: 20px;padding-left: 10px;"><b>{{languageWorking.workingtime |
                    translate}}</b>
            </h2>
            <div class="row" style="margin-right: 30px;">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style="text-align: center;padding-left: 40px;">
                    <h5>{{languageWorking.start | translate}} : </h5>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <h5>{{dateStart | date: 'hh:mm:ss a'}}</h5>
                </div>
            </div>
            <hr class="light-hr" style="width: 312px;margin-left: 70px;">
            <div class="row" style="margin-right: 30px;">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style="text-align: center;padding-left: 40px;">
                    <h5>{{languageWorking.end | translate}} : </h5>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <div class="ngx-timepicker-field-example"
                        style="display: flex;justify-content: center;margin-bottom: 0px;margin-left: -5px;">
                        <!-- <ngx-timepicker-field [controlOnly]="true" (timeChanged)="onChangeTimeEnd($event)" [format]="24">
                        </ngx-timepicker-field> -->
                        <form [formGroup]="workForm">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>{{languageWorking.enddate | translate}}</mat-label>
                                <input matInput formControlName="end" [owlDateTimeTrigger]="endDate"
                                    [owlDateTime]="endDate" placeholder="Choose a date" [max]="max" [min]="min">
                                <mat-icon matSuffix [owlDateTimeTrigger]="endDate" class="date-icon">today</mat-icon>
                                <owl-date-time #endDate></owl-date-time>

                                <!-- <input matInput formControlName="end" [owlDateTimeTrigger]="endDate"
                                    [owlDateTime]="endDate" placeholder="Choose a date">
                                <mat-icon matSuffix [owlDateTimeTrigger]="endDate" class="date-icon">today</mat-icon>
                                <owl-date-time [pickerType]="'timer'"#endDate></owl-date-time> -->
                            </mat-form-field>
                        </form>

                    </div>
                    <b *ngIf="endDateAfter == true" style="color: rgb(175, 0, 0);">* กรอกวันที่ไม่ถูกต้อง</b>
                    <b *ngIf="errorEndDate == true" style="color: rgb(175, 0, 0);">* กรุณากรอกวันที่</b>
                </div>
            </div>
            <div class="row" *ngIf="roleUser == 'Doctor'">
                <div class="col-xl-12"
                    style="margin-top: 12px;margin-bottom: 10px;text-align: center;margin-left: 21px;">
                    <button mat-raised-button style="margin-right: 10px;background-color: #ff7f50;color: white;"
                        *ngIf="dataGetTimeWork != null && dataTimeWork != null && dataGetTimeWork.statusUpdate == 'start'"
                        (click)="UpdateTimeStart()">{{languageWorking.updatetime | translate}}</button>
                    <button mat-raised-button style="margin-right: 10px;background-color: #ff7f50;color: white;"
                        *ngIf="dataGetTimeWork == null && dataGetTimeWork.statusUpdate == 'start' || dataTimeWork == null && dataGetTimeWork.statusUpdate == 'start'"
                        (click)="AddTimeStart('doc')">{{languageWorking.starttime | translate}}</button>
                    <button mat-raised-button style="margin-right: 10px;background-color: #ff7f50;color: white;"
                        *ngIf="dataGetTimeWork != null && dataTimeWork != null && dataGetTimeWork.statusUpdate == 'update'"
                        (click)="UpdateTimeStart()">
                        {{languageWorking.updatetime | translate}}</button>
                    <button mat-raised-button style="margin-right: 10px;background-color: #ff7f50;color: white;"
                        *ngIf="dataGetTimeWork == null && dataGetTimeWork.statusUpdate == 'update'|| dataTimeWork == null && dataGetTimeWork.statusUpdate == 'update'"
                        (click)="AddTimeStart('doc')">{{languageWorking.starttime | translate}}</button>
                    <button mat-raised-button color="primary" style="background-color: #acacac;"
                        *ngIf="dataGetTimeWork != null && dataTimeWork != null && dataGetTimeWork.statusUpdate == 'update'"
                        (click)="stopTimeWork()">{{languageWorking.stopwork | translate}}</button>
                    <button mat-raised-button
                        *ngIf="dataGetTimeWork == null && dataGetTimeWork.statusUpdate == 'update'|| dataTimeWork == null && dataGetTimeWork.statusUpdate == 'update'"
                        color="primary" style="background-color: #acacac;margin-left: 10px;"
                        (click)="dialogRef.close('Cancel')">{{languageWorking.cancel | translate}}</button>

                    <button mat-raised-button *ngIf="dataGetTimeWork.statusUpdate == 'start'" color="primary"
                        style="background-color: #acacac;margin-left: 10px;"
                        (click)="dialogRef.close('Cancel')">{{languageWorking.cancel | translate}}</button>

                </div>
            </div>
            <div class="row" *ngIf="roleUser == 'Nutritionist'">
                <div class="col-xl-12"
                    style="margin-top: 12px;margin-bottom: 10px;text-align: center;margin-left: 5px;">
                    <button mat-raised-button style="margin-right: 10px;background-color: #ff7f50;color: white;"
                        *ngIf="dataGetTimeWork.statusUpdate == 'start'"
                        (click)="AddTimeStart('Nutri')">{{languageWorking.starttime | translate}}</button>
                    <button mat-raised-button style="margin-right: 10px;background-color: #ff7f50;color: white;"
                        *ngIf="dataGetTimeWork.statusUpdate == 'update'" (click)="UpdateTimeStart()">
                        {{languageWorking.updatetime | translate}}</button>
                    <button mat-raised-button color="primary" style="background-color: #acacac;"
                        *ngIf="dataGetTimeWork != null && dataGetTimeWork.statusUpdate == 'update'"
                        (click)="stopTimeWorkNut()">{{languageWorking.stopwork | translate}}</button>
                    <button mat-raised-button *ngIf="dataGetTimeWork.statusUpdate == 'start'" color="primary"
                        style="background-color: #acacac;margin-left: 10px;"
                        (click)="dialogRef.close('Cancel')">{{languageWorking.cancel | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>