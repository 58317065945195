export class GlobalConstants {

  public static REST_API_SW: string = "https://rl-1243.achatsocial.com"; // 1243 webcore

  public static REST_API_CHAT: string = "https://rl-chat.achatsocial.com"; // 4520 chat

  public static REST_API_TELE: string = "https://rl-2477.achatsocial.com"; // 2477 tele

  public static URL_PART_2021: string = "https://rl-2443.achatsocial.com"; // 2443 achat_health

  // public static REST_API_HEALTH: string = "https://personal.medilifewellness.com";

  // public static REST_API_PDF: string = "https://personal.medilifewellness.com";

  public static REST_API_HEALTH: string = 'https://rl-personal.achatsocial.com'; // http://releep.achatsocial.com:2025 // personal health

  public static REST_API_PDF: string = 'https://rl-personal.achatsocial.com'; // http://releep.achatsocial.com:2025 // personal health pdf




}
