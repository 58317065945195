import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import Swal from "sweetalert2";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguageAdmin } from "src/app/admin/interface-admin/language-item-admin";

@Component({
  selector: "app-location-pharmacy",
  templateUrl: "./location-pharmacy.component.html",
  styleUrls: ["./location-pharmacy.component.scss"],
})
export class LocationPharmacyComponent implements OnInit {
  title: string = "AGM project";
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  id: any;
  role: any;
  datapharmacys: any;
  datalatitude: any;
  datalongitude: any;
  idPharmacy: any;
  languageItemsAdminAllPharmacy: any;
  transLang: any;
  adminRole: string;

  @ViewChild("search")
  public searchElementRef: ElementRef;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public pharmacysService: PharmacyService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    var dataLocal = localStorage.getItem("currentUser");
    this.idPharmacy = this.activatedRoute.snapshot.paramMap.get("idPhamacy");
    var data_JsonToken = JSON.parse(dataLocal);
    this.id = data_JsonToken.id;
    this.role = data_JsonToken.role;
    this.adminRole = JSON.parse(localStorage.getItem("currentUser")).role;
  }

  ngOnInit() {
    this.languageItemsAdminAllPharmacy = LanguageAdmin[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.transLang = localStorage.getItem("lang");
    });
    if (this.role == "Admin") {
      this.id = this.idPharmacy;
    } else {
      this.id;
    }
    this.loadDataPharmacy(this.id);
  }
  async loadDataPharmacy(id: string) {
    var dataPharmacyList: any;
    await this.pharmacysService
      .GetPharmacysList_(id)
      .then((resPharmacyList) => {
        dataPharmacyList = resPharmacyList;
        this.datapharmacys = dataPharmacyList.result;
        if (
          dataPharmacyList.result.phaR_Lat == null &&
          dataPharmacyList.result.phaR_Lng == null
        ) {
          this.datalatitude = 13.7462411;
          this.datalongitude = 100.5347402;
        } else {
          this.datalatitude = dataPharmacyList.result.phaR_Lat;
          this.datalongitude = dataPharmacyList.result.phaR_Lng;
        }
        this.map();
      });
  }
  map() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();

          this.zoom = 18;
        });
      });
    });
  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = this.datalatitude;
        this.longitude = this.datalongitude;
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 18;
            this.address = results[0].formatted_address;
          } else {
            window.alert(
              this.transLang == "en" ? "No results found." : "ไม่พบผลลัพธ์"
            );
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }

  openDialogUpdateLocation() {
    Swal.fire({
      title:
        this.transLang == "en"
          ? "Want to change your address?"
          : "คุณต้องการที่จะแก้ไขที่อยู่ใช่หรือไม่?",
      html:
        this.transLang == "en"
          ? "The address you want to change is "
          : "ที่อยู่ที่คุณต้องการแก้ไขคือ " + "<br>" + this.address,
      showCloseButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fas fa-map-marker-alt"></i> ' + this.transLang == "en"
          ? "Confirm"
          : "ยืนยัน",
      imageUrl: "assets/images/location.gif",
      imageWidth: 200,
      showCancelButton: true,
      confirmButtonColor: "#ff7f50",
      cancelButtonColor: "#bdc3c7",
      cancelButtonText: this.transLang == "en" ? "Cancel" : "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true) {
        var LocationPharmacy = {
          phaR_Id: this.id,
          phaR_Lat: this.latitude,
          phaR_Lng: this.longitude,
          phaR_AddressPinMap: this.address,
        };
        this.pharmacysService
          .UpdateLocationPhamacy_(LocationPharmacy)
          .then((resUpdateLocation) => { });
      } else {
        console.log("error : ");
      }
    });
  }
}
