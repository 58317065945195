import { AdminSettingComponent } from './../../admin/admin-setting/admin-setting.component';
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { ROUTES } from "./sidebar-items";
import { AuthService } from "src/app/core/service/auth.service";
import { Role } from "src/app/core/models/role";
import Swal from "sweetalert2";
import { GlobalConstants } from "src/app/common/global-constants";
import { DoctorTotalIncomeService } from "src/app/doctor/doctor-total-income/doctor-total-income.service";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import { Doctors } from "src/app/admin/doctors/alldoctors/doctors.model";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguageHeader } from "../header/interface-language/language-item-header";
import { MatDialog } from "@angular/material/dialog";
import { interval } from 'rxjs';
import * as moment from 'moment';
import { DoctorCalendarService } from 'src/app/doctor/doctor-calendar/doctor-calendar.service';
import { AppointmentsService } from 'src/app/doctor/appointments/appointments.service';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, OnDestroy {
  urlPath = GlobalConstants.REST_API_TELE;
  urlPathUser = GlobalConstants.URL_PART_2021;
  public sidebarItems: any[];
  level1Menu = "";
  level2Menu = "";
  level3Menu = "";
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  userDoc: Doctors;
  userPharmacys: any = [];
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  // idDoctor: string;
  id: string;
  nameDoctor: string;
  roleLogin: string;
  languageHeader: any;
  datatranslate: any;
  callNameRole: string;

  timeCountCalendar: any;
  timerCountDownCalendar: any;
  dataAppointmentsCalendar: any;
  countdownNowCalendar: any;
  ckeckShowCalendar: boolean = true;
  Day: any;
  private interval: any;
  dataGetTimeWork: any;

  homePage: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    public doctorService: DoctorTotalIncomeService,
    public pharmacysService: PharmacyService,
    private sharedServiceService: SharedServiceService,
    public translate: TranslateService,
    public dialog: MatDialog,
    public calendarService: DoctorCalendarService,
    public workTimeService: AppointmentsService,
  ) {
    this.translate.use(localStorage.getItem("lang"));
    var dataLocal = localStorage.getItem("currentUser");


    if (dataLocal != null) {
      var data_ = JSON.parse(dataLocal);
      // this.idDoctor = data_.id;
      this.id = data_.id;
      this.nameDoctor = data_.username;
      this.roleLogin = data_.role;
      // console.log("idPharmacy",this.id);
    }

    const body = this.elementRef.nativeElement.closest("body");
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ["admin", "doctor", "patient"];
        const currenturl = event.url.split("?")[0];
        const firstString = currenturl.split("/").slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = event.url.split("/")[2];
          this.level2Menu = event.url.split("/")[3];
        } else {
          this.level1Menu = event.url.split("/")[1];
          this.level2Menu = event.url.split("/")[2];
        }

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, "overlay-open");
      }
    });

    router.events.forEach((event) => {
      // if (event instanceof NavigationStart) {
      //   console.log(event[`url`]);
      //   console.log(`/doctor/appointments-calendar/${this.id}`);

      //   if (event['url'] == '/doctor/appointments-calendar/47') {
      //     this.ckeckShowCalendar = false;
      //   } else {
      //     this.ckeckShowCalendar == true;
      //   }
      // }
      if (event instanceof NavigationEnd) {
        this.getConvosationDoc();
        if (event['url'] == `/doctor/appointments-calendar/${this.id}`) {
          this.ckeckShowCalendar = false;
        } else {
          this.ckeckShowCalendar = true;
        }
      }
    });
  }
  @HostListener("window:resize", ["$event"])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, "overlay-open");
    }
  }
  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = "0";
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains("toggled");
    if (hasClass) {
      this.renderer.removeClass(event.target, "toggled");
    } else {
      this.renderer.addClass(event.target, "toggled");
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = "0";
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = "0";
    } else {
      this.level3Menu = element;
    }
  }
  async ngOnInit() {
    this.languageHeader = LanguageHeader[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
    });

    this.loadDataPharmacy(this.id);
    this.loadDatachangeDate();
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;
      this.userFullName = this.authService.currentUserValue.username;
      this.userImg = this.authService.currentUserValue.img;

      this.sidebarItems = ROUTES.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf("All") !== -1
      );

      this.sharedServiceService.languageValue.subscribe((message) => {
        this.translate.use(message);
        this.datatranslate = localStorage.getItem("lang");
        if (userRole === Role.Pharmacy) {
          this.userType = Role.Pharmacy;
          this.callNameRole = "ร้านขายยา";
          if (this.datatranslate == "en") {
            this.callNameRole = "Pharmacy";
          }
        } else if (userRole === Role.Patient) {
          this.userType = Role.Patient;
          this.callNameRole = "ผู้ป่วย";
          if (this.datatranslate == "en") {
            this.callNameRole = "Patient";
          }
        } else if (userRole === Role.Doctor) {
          this.userType = Role.Doctor;
          this.getTimeWork(this.id);
          this.callNameRole = "หมอ";
          if (this.datatranslate == "en") {
            this.callNameRole = "Doctor";
          }
          this.userDoctor();
        } else if (userRole === Role.Distributor) {
          this.userType = Role.Distributor;
          this.callNameRole = "ผู้จัดจำหน่าย";
          if (this.datatranslate == "en") {
            this.callNameRole = "Distributor";
          }
        } else if (userRole === Role.Nutritionist) {
          this.userType = Role.Nutritionist;
          this.callNameRole = "นักโภชนาการ";
          if (this.datatranslate == "en") {
            this.callNameRole = "Nutritionist";
          }
        } else if (userRole === Role.Dealer) {
          this.userType = Role.Dealer;
          this.callNameRole = "ดีลเลอร์";
          if (this.datatranslate == "en") {
            this.callNameRole = "Dealer";
          }
        } else if (userRole === Role.TelSupport) {
          this.userType = Role.TelSupport;
          this.callNameRole = "RUX's Care Team";
          if (this.datatranslate == "en") {
            this.callNameRole = "RUX's Care Team";
          }
        } else if (userRole === Role.Audit) {
          this.userType = Role.Audit;
          this.callNameRole = "ผู้ตรวจสอบ";
          if (this.datatranslate == "en") {
            this.callNameRole = "Audit";
          }
        } else if (userRole === Role.AdminViewOnly) {
          this.userType = Role.AdminViewOnly;
          this.callNameRole = "แอดมิน";
          if (this.datatranslate == "en") {
            this.callNameRole = "Admin";
          }
        } else if (userRole === Role.Packer) {
          this.userType = Role.Packer;
          this.callNameRole = "Deliver";
          if (this.datatranslate == "en") {
            this.callNameRole = "Deliver";
          }
        } else if (userRole === Role.Executive) {
          this.userType = Role.Executive;
          this.callNameRole = "ผู้บริหาร";
          if (this.datatranslate == "en") {
            this.callNameRole = "Executive";
          }
        } else {
          this.userType = Role.Admin;
          this.callNameRole = "แอดมิน";
          if (this.datatranslate == "en") {
            this.callNameRole = "SuperAdmin";
          }
        }
      });
    }

    // this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;
    this.notiAppointment();

    this.sharedServiceService.iscountDownAppointmentSidebar.subscribe((message) => {
      if (message == true) {
        this.getConvosationDoc();
      }
    });
  }


  notiAppointment() {
    this.sharedServiceService.currentMessage.subscribe((message) => {
      var indexBadge = this.sidebarItems.findIndex(
        (x) => x.path == "/doctor/appointments"
      );
      // console.log("notiAppointment index ", indexBadge, " : ", this.sidebarItems);
      // console.log(message);
      if (indexBadge != -1)
        this.sidebarItems[indexBadge].badge = message.toString();
    });

    this.sharedServiceService.countNotiAlertChangDateByuser.subscribe((message) => {
      var indexBadge = this.sidebarItems.findIndex(
        (x) => x.path == "/doctor/appointments-calendar"
      );
      // console.log("notiAppointment index ", indexBadge, " : ", this.sidebarItems);
      // console.log(message);
      if (indexBadge != -1)
        this.sidebarItems[indexBadge].badge = message.toString();

    });
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
    clearInterval(this.interval);
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + "";
    this.listMaxWidth = "500px";
  }
  isOpen() {
    return this.bodyTag.classList.contains("overlay-open");
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, "ls-closed");
    } else {
      this.renderer.removeClass(this.document.body, "ls-closed");
    }
  }
  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("submenu-closed")) {
      this.renderer.addClass(this.document.body, "side-closed-hover");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    }
  }
  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest("body");
    if (body.classList.contains("side-closed-hover")) {
      this.renderer.removeClass(this.document.body, "side-closed-hover");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  getTimeWork(idDoc: any) {
    this.workTimeService.GetWorkByDrId_(idDoc).then((resAddTime) => {
      this.dataGetTimeWork = resAddTime;
    })
  }

  logout() {
    this.customWithFunctionSkill();
  }

  customWithFunctionSkill() {
    Swal.fire({
      title: this.datatranslate == "en" ? "Are you sure?" : "คุณแน่ใจไหม?",
      text: this.datatranslate == "en" ? "Do you want to log out?" : "คุณต้องการที่จะออกจากระบบ",
      imageUrl: "assets/images/logout/log-out.gif",
      imageHeight: 140,
      showCancelButton: true,
      confirmButtonColor: "#ff591b",
      cancelButtonColor: "#FFFFFE",
      confirmButtonText: this.datatranslate == "en" ? "Yes" : "ใช่",
      cancelButtonText: this.datatranslate == "en" ? "No" : "ไม่",
      allowOutsideClick: false,
      didOpen: () => {
        const confirmButton = Swal.getConfirmButton();
        const cancelButton = Swal.getCancelButton();

        confirmButton.style.padding = '10px 60px';
        confirmButton.style.fontSize = '16px';
        confirmButton.style.borderRadius = '10px'; // ใช้ border-radius ตรงนี้

        cancelButton.style.padding = '10px 60px';
        cancelButton.style.fontSize = '16px';
        cancelButton.style.borderRadius = '10px'; // ใช้ border-radius ตรงนี้
        cancelButton.style.border = '2px solid rgba(0,0,0,.5)'; // กรอบสีดำให้ปุ่ม No
        cancelButton.style.color = 'black'; // เปลี่ยนสีตัวหนังสือปุ่ม No เป็นสีดำ
      }
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.nameDoctor != "AdminEp&it") {
          var dataLocal = localStorage.getItem("currentUser");
          if (dataLocal != null) {
            var data_ = JSON.parse(dataLocal);
            localStorage.removeItem("currentUser");
            localStorage.removeItem("onesignal-allow");
            localStorage.removeItem("changTimes");
            localStorage.removeItem("showDialogWorkTime");
            localStorage.removeItem("referenceDigipay");
            localStorage.removeItem("NewOrder");

            if (this.dataGetTimeWork != null) {
              this.workTimeService.DeleteWorkByDrId_(this.dataGetTimeWork.worK_Id).then((resDeleteTime) => { });
              this.workTimeService.DeleteWorkByNutritionistId_(this.dataGetTimeWork.worK_Id).then((resDeleteTime) => { });
            }

            if (data_.role != "Dealer") {
              this.router.navigate([`/authentication/signout/${this.id}`], { replaceUrl: true }).then(() => {
                window.location.reload();
              });
            } else {
              this.router.navigate([`/authentication/signin-dealer`]);
            }
          } else {
            this.router.navigate([`/authentication/signin`], { replaceUrl: true }).then(() => {
              window.location.reload();
            });
          }

          localStorage.removeItem("currentUser");
          localStorage.removeItem("onesignal-allow");
          localStorage.removeItem("showDialogWorkTime");
          localStorage.removeItem("referenceDigipay");
          localStorage.removeItem("NewOrder");
        } else {
          localStorage.removeItem("currentUser");
          localStorage.removeItem("onesignal-allow");
          localStorage.removeItem("showDialogWorkTime");
          localStorage.removeItem("referenceDigipay");
          localStorage.removeItem("NewOrder");

          this.router.navigate([`/authentication/signin`], { replaceUrl: true }).then(() => {
            window.location.reload();
          });
        }
      } else {
        console.log("error : ");
      }
    });
  }


  userDoctor() {
    var doctorList: any;
    this.doctorService.GetDoctorList_(this.id).then((resDoctorList) => {
      doctorList = resDoctorList;
      this.userDoc = doctorList;
    });
  }
  async loadDataPharmacy(idPharmacy: string) {
    var dataPharmacyList: any;
    await this.pharmacysService
      .GetPharmacysList_(idPharmacy)
      .then((resPharmacyList) => {
        dataPharmacyList = resPharmacyList;
        this.userPharmacys = dataPharmacyList.result;
      });
  }

  adminSetting() {
    this.dialog.open(AdminSettingComponent, {
      width: "30%",
    });
  }

  getConvosationDoc() {
    this.authService.GetConvosationDoc_(Number(this.id)).then((resGetConvosation) => {
      var dataGetConvosationcalendar: any;
      var dataPush: any = [];
      dataGetConvosationcalendar = resGetConvosation;
      dataGetConvosationcalendar = dataGetConvosationcalendar.filter(x => x.convoDoctorAccept == true && x.convoDateAppointment != null && new Date(x.convoDateAppointment) >= new Date());
      if (dataGetConvosationcalendar.length > 0) {
        for (let i = 0; i < dataGetConvosationcalendar.length; i++) {
          if (dataGetConvosationcalendar[i].convoDoctorAccept == true && new Date(dataGetConvosationcalendar[i].convoDateAppointment) >= new Date()) {
            dataPush.push(dataGetConvosationcalendar[i]);
          }
          if (i == dataGetConvosationcalendar.length - 1) {
            this.dataAppointmentsCalendar = dataPush.sort((x, y) => +new Date(x.convoDateAppointment) - +new Date(y.convoDateAppointment));
          }
        }
        // this.timerCountDownCalendar = setInterval(this.getElapsedTimeCalendar, 1000);
        // this.getElapsedTimeCalendar(this.dataAppointmentsCalendar[0].convoDateAppointment)
        var date1 = new Date(this.dataAppointmentsCalendar[0].convoDateAppointment).getTime();
        var date2 = new Date().getTime() + 1000 * 60 * 60 * 24;
        // console.log(new Date(this.dataAppointmentsCalendar[0].convoDateAppointment).getTime());
        // console.log(new Date().getTime() + 1000 * 60 * 60 * 24);

        if (date1 <= date2) {
          this.calendarService.routeData().subscribe(data => {
            // console.log("data--->", data);
            this.dataAppointmentsCalendar = data;
          });
          this.checkCountDownTimeCalendar(this.dataAppointmentsCalendar[0].convoDateAppointment);
        } else {
          this.dataAppointmentsCalendar = null;
        }
        // console.log("dataAppointmentsCalendar", this.dataAppointmentsCalendar);

      } else {
        this.dataAppointmentsCalendar = null;
        // setInterval(() => {
        //   this.loading = false;
        // }, 500);
      }
    });
  }

  checkCountDownTimeCalendar(data_: any) {
    var dataHours = data_;

    this.timeCountCalendar = data_;

    this.countDownTimeCalendar();
    this.timerCountDownCalendar = interval(1000).subscribe((x) => {
      this.countDownTimeCalendar();
    });
  }

  countDownTimeCalendar() {
    var now = new Date();
    var timeOut = new Date(this.timeCountCalendar);
    // this.Day = new Date(date__).getDate();
    // console.log("grgrg", this.Day);

    var date__ = timeOut.getTime() - now.getTime() - (1000 * 60 * 60 * 7);
    // (1000 * 60 * 60 * 24)
    // console.log(diffDays, diffHrs, diffMins, seconds_);

    // var countdown = new Date(date__);
    // var time = countdown.setHours(countdown.getHours() - 7);
    var countdownNow = moment(date__).format("HH:mm:ss");
    // console.log(countdownNow);

    this.countdownNowCalendar = countdownNow;
    // console.log("oooo", countdownNow);

    if (countdownNow == "00:00:00") {
      this.timerCountDownCalendar.unsubscribe();
      countdownNow = "00:00:00";
      this.countdownNowCalendar = "00:00:00";
      this.dialogTimeOut();
    }
    if (countdownNow == "01:00:00") {
      this.dialogTime(this.datatranslate == "en" ? "It's almost time for the appointment ?" : "ใกล้ถึงเวลานัดแล้ว ?", this.datatranslate == "en"
        ? "There are 1 hour left until the meeting time ?"
        : "เหลือเวลาอีก 1 ชั่วโมงจะถึงเวลานัด")
    }
    if (countdownNow == "00:30:00") {
      this.dialogTime(this.datatranslate == "en" ? "It's almost time for the appointment ?" : "ใกล้ถึงเวลานัดแล้ว ?", this.datatranslate == "en"
        ? "There are 30 minutes left until the meeting time ?"
        : "เหลือเวลาอีก 30 นาทีจะถึงเวลานัด")
    }
    if (countdownNow == "00:10:00") {
      this.dialogTime(this.datatranslate == "en" ? "It's almost time for the appointment ?" : "ใกล้ถึงเวลานัดแล้ว ?", this.datatranslate == "en"
        ? "There are 10 minutes left until the meeting time ?"
        : "เหลือเวลาอีก 10 นาทีจะถึงเวลานัด")
    }
  }


  async loadDatachangeDate() {
    await this.calendarService.GetDoctorUpdateAppointmentAfterPaymentByDoctor_(Number(this.id)).then(resppointmentAfter => {
      if (resppointmentAfter.data.length > 0) {
        resppointmentAfter.data.forEach(element => {
          var targetDate: Date = new Date(element.dataRequestNewDate.dateAppointment.toString());
          targetDate.setHours(targetDate.getHours() + 6);
          // targetDate.setMinutes(targetDate.getMinutes() + 50);
          element.dataRequestNewDate['countdown'] = this.updateCountdown(targetDate);

          this.interval = setInterval(() => {
            element.dataRequestNewDate['countdown'] = this.updateCountdown(targetDate);
            if (element.dataRequestNewDate['countdown'] == "Countdown expired") {
              setTimeout(() => {
                this.dialogTimeOut();
                // window.location.reload();
              }, 3000);
            }
          }, 1000);
        });
        // console.log(">> : ", this.dataAppointmentAfterPayment);

      }
    });
  }

  updateCountdown(targetDate: Date): string {
    const now = new Date();
    const timeDiff = targetDate.getTime() - now.getTime();
    if (timeDiff <= 0) {
      this.calendarService.CheckNewAppointmentAfterPaymentTimeOut_().then();
      return 'Countdown expired';
    } else {
      const seconds = Math.floor(timeDiff / 1000) % 60;
      const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
      const hours = Math.floor(timeDiff / 1000 / 60 / 60);
      return `${this.formatTime(hours)}:${this.formatTime(minutes)}:${this.formatTime(seconds)}`;
    }
  }

  formatTime(time: number): string {
    return time < 10 ? `0${time}` : `${time}`;
  }

  dialogTime(title: string, text: string) {
    Swal.fire({
      title: title,
      text: text,
      imageUrl: "assets/images/clock-time.gif",
      imageHeight: 140,
      showCancelButton: false,
      confirmButtonColor: "#ff7f50",
      // cancelButtonColor: "#bdc3c7",
      confirmButtonText: this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      // cancelButtonText: this.datatranslate == "en" ? "Cancel" : "ยกเลิก",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.router.navigate([`/doctor/appointments-calendar/${this.id}`]);
      }
    })
  }
  dialogTimeOut() {
    Swal.fire({
      title: this.datatranslate == "en" ? "It's the appointed time." : "ถึงเวลานัดแล้ว ?",
      text:
        this.datatranslate == "en"
          ? "Agree to enter the discussion page."
          : "กด ยืนยัน เพื่อเข้าสู่หน้าสนทนา",
      imageUrl: "assets/images/clock-time.gif",
      imageHeight: 140,
      showCancelButton: false,
      confirmButtonColor: "#ff7f50",
      confirmButtonText: this.datatranslate == "en" ? "Confirm" : "ยืนยัน",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed == true) {
        var idUserConF = localStorage.getItem("idUserConference");
        this.getConvosationDoc();
        this.router.navigate([
          `/doctor/doctor-chats/video/${this.id}_${idUserConF}/start`,
        ]);
      }
    })
  }
  getElapsedTime(entry: any, data: any) {
    // console.log(data);
    let totalSeconds = Math.floor(
      (new Date(entry).getTime() - new Date().getTime()) / 1000
    );

    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (totalSeconds >= 3600) {
      hours = Math.floor(totalSeconds / 3600);
      totalSeconds -= 3600 * hours;
    }

    if (totalSeconds >= 60) {
      minutes = Math.floor(totalSeconds / 60);
      totalSeconds -= 60 * minutes;
    }

    seconds = totalSeconds;
    var classNameEvent = "success";
    // var classColorsEvent = "success";
    var today = new Date();
    var diffMs = new Date(entry).getTime() - today.getTime();
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minute


    var seconds_ = seconds
    if (minutes == 0 && seconds < 0) {
      seconds_ = 0;
      // window.location.reload();
      this.getConvosationDoc();
    }
    return {
      day: diffDays,
      hours: hours,
      minutes: minutes,
      seconds: seconds_,
      classNameEvent: classNameEvent,
    };
  }
  openCalendar() {
    this.router.navigate([
      `/doctor/appointments-calendar/${this.id}`,
    ]);
  }
}
