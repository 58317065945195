import {
  Component,
  ElementRef,
  Inject,
  LOCALE_ID,
  OnInit,
  ViewChild,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UnsubscribeOnDestroyAdapter } from "src/app/shared/UnsubscribeOnDestroyAdapter";
import { PharmacyService } from "../pharmacy.service";
import { FormDrugListComponent } from "./dialogs/form-drug-list/form-drug-list.component";
import { FormCategoryComponent } from "./dialogs/form-category/form-category.component";
import { Drug } from "./drug.model";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import Swal from "sweetalert2";
import { ViewFormDrugListComponent } from "./dialogs/view-form-drug-list/view-form-drug-list.component";
import { style } from "@angular/animations";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "../interface-pharmacy/language-item-pharmacy";

@Component({
  selector: "app-drug-list",
  templateUrl: "./drug-list.component.html",
  styleUrls: ["./drug-list.component.scss"],
})
export class DrugListComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataLoadingDruglist: boolean = true;
  dataLoadingCategory: boolean = true;
  dataLoadingDrugStore: boolean = true;
  druglists: Drug | null;
  dataCategoryMedicine: any = [];
  dataSource: MatTableDataSource<Drug>;
  displayedColumns: string[] = [
    "medcName",
    "medcPrice",
    "medcQty",
    "medcUnit",
    "medcDescription",
    "medcCaution",
    "Actions",
  ];
  dataDrugList = [];
  filteredData = [];
  dataLoading: boolean = true;
  idPharmacy: string;
  textDes: number = 20;
  languagePharmacy: any;
  datatranslate: any;
  checkBank: boolean = true;
  adminRole: string;

  constructor(
    public httpClient: HttpClient,
    public dialog: MatDialog,
    public pharmacyService: PharmacyService,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    @Inject(LOCALE_ID) private locale: string,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    super();
    // var dataLocal = localStorage.getItem("currentUser");
    // var dataLocalJSON = JSON.parse(dataLocal);
    // this.idPharmacy = dataLocalJSON.id;
    this.translate.use(localStorage.getItem("lang"));
    this.idPharmacy = this.activatedRoute.snapshot.paramMap.get("idPhamacy");
    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);
    this.adminRole = JSON.parse(localStorage.getItem("currentUser")).role;
  }

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  ngOnInit() {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.datatranslate = localStorage.getItem("lang");
    });
    this.LoadingDrugCategorylist();
    this.loadDataDrug(this.idPharmacy);
    this.getBank();
    // console.log("bbbbbs", this.dataSource);
  }

  refresh() { }

  filterDataDruglist(event) { }

  getBank() {
    this.pharmacyService
      .GetBankPhar_(this.idPharmacy, "pharmacy")
      .then((res) => {
        var success: any = res;
        if (success.status == false) {
          this.checkBank = false;
        }
      });
  }
  addDruglistNew() {
    let tempDirection;
    if (this.checkBank != false) {
      if (localStorage.getItem("isRtl") === "true") {
        tempDirection = "rtl";
      } else {
        tempDirection = "ltr";
      }
      const dialogRef = this.dialog.open(FormDrugListComponent, {
        data: {
          druglists: this.druglists,
          action: "add",
        },
        direction: tempDirection,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined) {
          this.loadDataDrug(this.idPharmacy);
          this.showNotification(
            "snackbar-success",
            this.datatranslate == "en"
              ? "Add Record Successfully...!!!"
              : "เพิ่มบันทึกสำเร็จ...!!!",
            "bottom",
            "center"
          );
        }
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "กรุณาติดต่อแอดมินเพื่อทำการเพิ่มบัญชีธนาคาร ก่อนเพิ่มสินค้า",
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  addCategoryNew() {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(FormCategoryComponent, {
      data: {
        drugCategory: this.druglists,
        action: "add",
      },
      direction: tempDirection,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.LoadingDrugCategorylist();
        this.showNotification(
          "snackbar-success",
          this.datatranslate == "en"
            ? "Add Record Successfully...!!!"
            : "เพิ่มบันทึกสำเร็จ...!!!",
          "bottom",
          "center"
        );
      }
    });
  }

  editCategory(dataEdit: any) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(FormCategoryComponent, {
      data: {
        drugCategory: dataEdit,
        action: "edit",
      },
      direction: tempDirection,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        this.LoadingDrugCategorylist();
        this.showNotification(
          "snackbar-success",
          this.datatranslate == "en"
            ? "Update Record Successfully...!!!"
            : "อัพเดทบันทึกสำเร็จ...!!!",
          "bottom",
          "center"
        );
      }
    });
  }

  deleteCategory(idCategory: string) {
    Swal.fire({
      title: this.datatranslate == "en" ? "Are you sure?" : "คุณแน่ใจไหม?",
      text:
        this.datatranslate == "en"
          ? "You won't be able to revert this!"
          : "คุณจะไม่สามารถย้อนกลับได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#B1CAE3",
      cancelButtonColor: "#ff7f50",
      confirmButtonText:
        this.datatranslate == "en" ? "Yes, delete it!" : "ใช่ ลบทิ้ง!",
      cancelButtonText: this.datatranslate == "en" ? "cancle" : "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.pharmacyService
          .DeleteCategoryMedicine_(idCategory)
          .then((resDeleteCategory) => {
            if (resDeleteCategory == "Success Process") {
              this.dataCategoryMedicine = this.dataCategoryMedicine.filter(
                (item) => item.medcaT_Id != idCategory
              );
              this.showNotification(
                "snackbar-success",
                this.datatranslate == "en"
                  ? "Delete Record Successfully...!!!"
                  : "ลบบันทึกสำเร็จ...!!!",
                "bottom",
                "center"
              );
            }
          });
      }
    });
  }

  LoadingDrugCategorylist() {
    this.pharmacyService
      .GetCategoryMedicine_()
      .then((resGetCategoryMedicine) => {
        this.dataCategoryMedicine = resGetCategoryMedicine;
        setTimeout(() => {
          this.dataLoadingCategory = false;
        }, 1000);
      });
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  loadDataDrug(idPharmacy: string) {
    var dataDrug: any;
    this.pharmacyService.GetDrugList_(idPharmacy).then((resDrugSkill) => {
      dataDrug = resDrugSkill;
      // console.log(dataDrug);
      this.dataDrugList = dataDrug;
      this.dataSource = new MatTableDataSource(this.dataDrugList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      setTimeout(() => {
        this.dataLoading = false;
      }, 2000);
    });
  }

  deleteItem(index: number, idMedicine: string) {
    Swal.fire({
      title: this.datatranslate == "en" ? "Are you sure?" : "คุณแน่ใจไหม?",
      text:
        this.datatranslate == "en"
          ? "You won't be able to revert this!"
          : "คุณจะไม่สามารถย้อนกลับได้!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#B1CAE3",
      cancelButtonColor: "#ff7f50",
      confirmButtonText:
        this.datatranslate == "en" ? "Yes, delete it!" : "ใช่ ลบทิ้ง!",
      cancelButtonText: this.datatranslate == "en" ? "cancle" : "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.pharmacyService
          .DeleteMedicine_(idMedicine)
          .then((resDeleteMedicine) => {
            // console.log("resDeleteMedicine", resDeleteMedicine);
            if (resDeleteMedicine == "Success Process") {
              this.dataDrugList.splice(index, 1);
              this.dataSource = new MatTableDataSource(this.dataDrugList);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.showNotification(
                "snackbar-danger",
                this.datatranslate == "en"
                  ? "Delete Record Successfully...!!!"
                  : "ลบบันทึกสำเร็จ...!!!",
                " Record Delete Successfully...!!!",
                "center"
              );
              Swal.fire(
                this.datatranslate == "en" ? "Deleted!" : "ลบแล้ว!",
                this.datatranslate == "en"
                  ? "Your file has been deleted."
                  : "ไฟล์ของคุณถูกลบแล้ว",
                "success"
              );
            }
          });
      }
    });
  }

  editDrug(row) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    const dialogRef = this.dialog.open(FormDrugListComponent, {
      data: {
        druglists: row,
        action: "edit",
      },
      direction: tempDirection,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result != undefined) {
        var indexDataDrug = this.dataDrugList.findIndex(
          (d: { medcId: any }) => d.medcId == result.medcId
        );
        var indexDatafilteredData = this.filteredData.findIndex(
          (d: { medcId: any }) => d.medcId == result.medcId
        );

        this.dataDrugList[indexDataDrug].medcId = result.medcId;
        this.dataDrugList[indexDataDrug].medcName = result.medcName;
        this.dataDrugList[indexDataDrug].medcMedicalName =
          result.medcMedicalName;
        this.dataDrugList[indexDataDrug].medcDiscount = result.medcDiscount;
        this.dataDrugList[indexDataDrug].medcPrice = result.medcPrice;
        this.dataDrugList[indexDataDrug].medcQty = result.medcQty;
        this.dataDrugList[indexDataDrug].medcUnit = result.medcUnit;
        this.dataDrugList[indexDataDrug].medcDescription =
          result.medcDescription;
        this.dataDrugList[indexDataDrug].medcCaution = result.medcCaution;
        this.dataDrugList[indexDataDrug].medcHowToUse = result.medcHowToUse;
        this.dataDrugList[indexDataDrug].medcExpirationDate =
          result.medcExpirationDate;
        this.dataDrugList[indexDataDrug].medcImage = result.medcImage;
        this.dataDrugList[indexDataDrug].medcatId = result.medcatId;

        this.filteredData[indexDatafilteredData].medcId = result.medcId;
        this.filteredData[indexDatafilteredData].medcName = result.medcName;
        this.filteredData[indexDatafilteredData].medcMedicalName =
          result.medcMedicalName;
        this.filteredData[indexDatafilteredData].medcDiscount =
          result.medcDiscount;
        this.filteredData[indexDatafilteredData].medcPrice = result.medcPrice;
        this.filteredData[indexDatafilteredData].medcQty = result.medcQty;
        this.filteredData[indexDatafilteredData].medcUnit = result.medcUnit;
        this.filteredData[indexDatafilteredData].medcDescription =
          result.medcDescription;
        this.filteredData[indexDatafilteredData].medcCaution =
          result.medcCaution;
        this.filteredData[indexDatafilteredData].medcHowToUse =
          result.medcHowToUse;
        this.filteredData[indexDatafilteredData].medcExpirationDate =
          result.medcExpirationDate;
        this.filteredData[indexDatafilteredData].medcImage = result.medcImage;
        this.filteredData[indexDatafilteredData].medcatId = result.medcatId;

        this.showNotification(
          "snackbar-success",
          this.datatranslate == "en"
            ? "Update Record Successfully...!!!"
            : "อัพเดทบันทึกสำเร็จ...!!!",
          "bottom",
          "center"
        );
      }
    });
  }

  filterDatatable(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  detailsCall(row) {
    let tempDirection;
    if (localStorage.getItem("isRtl") === "true") {
      tempDirection = "rtl";
    } else {
      tempDirection = "ltr";
    }
    this.dialog.open(ViewFormDrugListComponent, {
      data: {
        druglists: row,
        action: "details",
      },
      direction: tempDirection,
      width: "70%",
      height: "70%",
    });
  }
}
