import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalConstants } from "src/app/common/global-constants";

@Injectable({
  providedIn: "root",
})
export class DoctorChatsService {
  //Http header
  httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
  };
  constructor(private httpClient: HttpClient) { }

  GetConvosation_(drId: number) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetConvosationById?drId=${drId}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetUserByID_(idUser: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/user/GetUserByID?idUser=${idUser}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetUserList_(dataID: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/user/GetUserList`;
      this.httpClient
        .post(API_URL, dataID, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetLastHistoryChatlist(idDoctor: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/chat_list/${idDoctor}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetHistoryChat(
    myMultiUser: string,
    getIDmyuser: string,
    FrienduserID: string
  ) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/get_chat_hist/${myMultiUser}/${getIDmyuser}/${FrienduserID}`;
      // console.log("API_URL ", API_URL);

      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
  // var url = chatServerIP +'get_chat_hist/' +  chatId +  '/' +  userId +    '/' + friendId;
  // var url = chatServerIP + 'get_old_chat_hist/' + chatId + '/' + topTimeStamp;
  GetOldHistoryChat(myMultiUser: string, topTimeStamp: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/get_old_chat_hist/${myMultiUser}/${topTimeStamp}`;
      // console.log("API_URL ", API_URL);

      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UploadIMGChatDoctor_(data: FormData) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/doctor/UploadImgBackOffice`;
      this.httpClient
        .post(API_URL, data, { responseType: "text" })
        // this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.u_Token}` }), responseType: "text" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetJWTTokenCall(username: string, email: string, img: string) {
    return new Promise<any>((resolve, reject) => {
      let API_URL = `https://api.achatsocial.com:4434/api/getConferToken?user_name=${encodeURIComponent(
        username
      )}&user_email=${encodeURIComponent(
        email
      )}&profile_path=${encodeURIComponent(img)}`;
      this.httpClient.get(API_URL, { responseType: "text" }).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  InsertNoteConversation_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/InsertNoteConversation`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetNoteConversationByUser_(idUser: string, type: string) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_TELE}/api/telemedicine/conversation/GetNoteConversationByUser?userId=${idUser}&typeTalk=${type}`;
      this.httpClient
        .get(API_URL, { headers: this.httpHeaders, responseType: "json" })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  UnsendMessage_(data: any) {
    return new Promise((resolve, reject) => {
      let API_URL = `${GlobalConstants.REST_API_CHAT}/unsendMessage`;
      this.httpClient
        .post(API_URL, data, {
          headers: this.httpHeaders,
          responseType: "json",
        })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
