import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "src/app/common/global-constants";
import { SharedServiceService } from "src/app/common/shared-service.service";
import { LanguagePharmacy } from "src/app/pharmacy/interface-pharmacy/language-item-pharmacy";
import { PharmacyService } from "src/app/pharmacy/pharmacy.service";
import Swal from "sweetalert2";
import { TransferModel } from "./transfer.model";

@Component({
  selector: "app-view-payment-dialog",
  templateUrl: "./view-payment-dialog.component.html",
  styleUrls: ["./view-payment-dialog.component.scss"],
})
export class ViewPaymentDialogComponent implements OnInit {
  REST_API_BANK = GlobalConstants.REST_API_TELE; //local
  selectedbankNamedoc = "ธนาคาร";
  failedTransfer: boolean = false;
  statusTransfer: boolean = false;
  amount: string;
  slipImage: string;
  viewTransfer: any;
  paymentForm: FormGroup;
  orderTransfer: TransferModel;
  dataTransfer: any;
  dataBankByIdPharAll: any;
  idPharmacy: any;
  idOrder: any;
  languagePharmacy: any;
  transLang: any;

  constructor(
    public dialogRef: MatDialogRef<ViewPaymentDialogComponent>,
    public pharmacyService: PharmacyService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    private sharedServiceService: SharedServiceService
  ) {
    this.translate.use(localStorage.getItem("lang"));
    this.slipImage = data.element.dataReceipt.recpT_SlipImage;
    this.idOrder = data.element.idOrder;
    this.amount = data.sum;
    this.dataTransfer = data;

    var dataLocal = localStorage.getItem("currentUser");
    var dataLocalJSON = JSON.parse(dataLocal);
    this.idPharmacy = dataLocalJSON.id;
    // console.log(this.dataTransfer);

    this.orderTransfer = new TransferModel({});

    this.paymentForm = this.createContactForm();
  }
  formControl = new FormControl("", [
    Validators.required,
    // Validators.email,
  ]);
  createContactForm(): FormGroup {
    return this.fb.group({
      transferStutus: this.statusTransfer,
      transferNote: [this.orderTransfer.transferNote],
    });
  }

  ngOnInit(): void {
    this.languagePharmacy = LanguagePharmacy[0];
    this.sharedServiceService.languageValue.subscribe((message) => {
      this.translate.use(message);
      this.transLang = localStorage.getItem("lang");
    });
    this.loadDataBank();
  }

  onSearchChangeBank(idValueBank: string): void {
    this.selectedbankNamedoc = idValueBank;
  }

  viewChangeTransfer(viewTransfer: boolean): void {
    this.failedTransfer = viewTransfer;
  }

  public async confirmUpdateTransfer(idNo: string): Promise<void> {
    var transferNoteBystatus: string = "";

    if (
      this.paymentForm.getRawValue().transferNote != null &&
      this.paymentForm.getRawValue().transferNote != ""
    ) {
      this.statusTransfer = false;
      transferNoteBystatus = this.paymentForm.getRawValue().transferNote;
    } else {
      this.statusTransfer = true;
    }
    var dataTransferSuccess: any;
    var dataUpdateTransfer = {
      RECPT_No: idNo,
      RECPT_TransferStutus: this.statusTransfer,
      RECPT_TransferNote: transferNoteBystatus,
    };
    Swal.fire({
      text:
        this.transLang == "en"
          ? "Please correcting your information."
          : "ตรวจสอบข้อมูลให้ถูกต้องก่อนบันทึก",
      imageUrl: "assets/images/swalImg.png",
      imageHeight: 130,
      showCancelButton: true,
      confirmButtonColor: "##ff7f50",
      cancelButtonColor: "#bdc3c7",
      confirmButtonText: this.transLang == "en" ? "Yes" : "ตกลง",
      cancelButtonText: this.transLang == "en" ? "Cancel" : "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed == true) {
        this.pharmacyService
          .UpdateTransfer_(dataUpdateTransfer)
          .then((resTransfer) => {
            dataTransferSuccess = resTransfer;
            if (dataTransferSuccess.status == true) {
              this.dataTransfer.element.dataReceipt.recpT_TransferNote =
                dataUpdateTransfer.RECPT_TransferNote;
              this.showNotification(
                "snackbar-success",
                dataTransferSuccess.msg,
                "bottom",
                "center"
              );
              var dataUpdatenew = {
                idOrder: this.idOrder,
                RECPT_No: dataUpdateTransfer.RECPT_No,
                RECPT_TransferStutus: dataUpdateTransfer.RECPT_TransferStutus,
                RECPT_TransferNote: dataUpdateTransfer.RECPT_TransferNote,
              };
              this.dialogRef.close(dataUpdatenew);
            }
          });
      }
    });
  }

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 3000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  loadDataBank() {
    var dataGetBankPhar: any;
    this.pharmacyService
      .GetBankPhar_(this.idPharmacy, "pharmacy")
      .then((resGetBankPhar) => {
        dataGetBankPhar = resGetBankPhar;
        this.dataBankByIdPharAll = dataGetBankPhar;
      });
  }
}
